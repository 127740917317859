import { useExitIntent } from 'use-exit-intent';

const config = {
  cookie: {
    daysToExpire: 15,
    key: 'use-exit-intent',
  },
  desktop: {
    triggerOnIdle: false,
    useBeforeUnload: false,
    triggerOnMouseLeave: true,
  },
  mobile: {
    triggerOnIdle: true,
    delayInSecondsToTrigger: 8
  },
};

const useExitIntentWithConfig = () => {
  const { registerHandler, unsubscribe } = useExitIntent(config);

  return {
    registerHandler,
    unsubscribe,
  };
};

export default useExitIntentWithConfig;