// const isProd = !process.env.NEXT_PUBLIC_IS_DEV;
const isProd = true;

export const gtm_ecommerce_view_item = (data) => {
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: data,
    });
  }
};

export const gtm_ecommerce_view_cart = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: data,
    });
  }
}

export const gtm_ecommerce_remove_from_cart = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: data,
    });
  }
}

export const gtm_ecommerce_add_to_cart = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: data,
    });
  }
}

export const gtm_ecommerce_select_item = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_item",
      ecommerce: data,
    });
  }
}

export const gtm_ecommerce_select_promotion = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_promotion",
      ecommerce: data,
    });
  }
}
export const gtm_ecommerce_viewItem_list = (data) => {
  
  if (isProd) {
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: data.item_list_id,
        items: data.items
      },
    });
  }
};
export const gtm_ecommerce_begin_checkout = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: data,
    });
  }
}

export const gtm_ecommerce_purchase = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: data,
    });
  }
}
export const gtm_add_payment_info = (data)=>{
  if (isProd) {
    window.dataLayer.push({
      event: "add_payment_info",
      ecommerce: data,
    });
  }
}
export const gtm_add_shipping_info = (data)=>{
  if (isProd) {
    window.dataLayer.push({
      event: "add_shipping_info",
      ecommerce: data,
    });
  }
}


export const gtm_ecommerce_earn_virtual_currency = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "earn_virtual_currency",
      ecommerce: data,
    });
  }
}

export const gtm_ecommerce_spend_virtual_currency = (data)=>{
  if (isProd) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "spend_virtual_currency",
      ecommerce: data,
    });
  }
}


