import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import styles from "@assets/css/header.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  getCoordinates,
  getWorkshopFilterMapMultiple,
} from "redux/actions/apiActions";
import {
  getLocationData,
  setAccessLocation,
  setSaveLocation,
} from "redux/actions/globalAction";
import {
  setCartJobItems,
  setCartProducts,
  setCartStage,
  setCartWorkshop,
} from "redux/actions/cartAction";
import Autocomplete from "react-google-autocomplete";
import { useRouter } from "next/router";
import axios from "axios";
import { GetInfo } from "lib/Location";

const LocationSection = ({
  getCoordinates,
  getLocationData,
  apiData,
  cart,
  globalData,
  getWorkshopFilterMapMultiple,
  handleToggleLocation,
  login,
  setLoader,
  setAccessLocation,
  setSaveLocation,
}) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };
  const router = useRouter();
  const [loaded, setLoaded] = useState(false);
  const [sLoaded, setSLoaded] = useState(false);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [latTemp, setLatTemp] = useState(0);
  const [longTemp, setLongTemp] = useState(0);
  const [zoom, setZoom] = useState(12);
  const [showWarning, setShowWarning] = useState(false);
  const [location, setLocation] = useState("");
  const [locationText, setLocationText] = useState("");
  const [dropDownSelection, setDropDownSelection] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({});
  const [showvalues, setShowvalues] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    const location_data = JSON.parse(
      sessionStorage.getItem("location_data") || JSON.stringify("")
    );

    setCurrentLocation(location_data.data);
    setLocationText(location_data.data?.EmirateArea);
    if (router.pathname == "/services/[...slug]") {
      const location = location_data.data?.EmirateArea.trim()
        .split(" ")
        .join("-")
        .toLowerCase()
        .split(".")
        .join("");

      router.push(
        `/services/${location || "dubai"}/${router.query.slug[1]}/${
          router.query.slug[2]
        }`
      );
    }
    if (location_data.data) {
      setAccessLocation(false);
    }
  }, [globalData.locationData, globalData.accessLocation, showvalues]);

  useEffect(() => {
    if (currentLocation && login) {
      setSaveLocation(true);
    } else {
      setSaveLocation(false);
    }
  }, [login]);

  useEffect(() => {
    setLocationText(currentLocation?.EmirateArea);
  }, [currentLocation]);

  useEffect(() => {
    if (dropDownSelection) {
      setDropDownSelection(false);
      return;
    }
  }, [globalData.locationData?.data, dropDownSelection]);

  useEffect(async () => {
    let result =
      apiData.workshopFilterMap?.filter((item) => item.Id === cart.workshop?.Id)
        .length === 1;
    if (result) {
      let lat = latTemp;
      let lang = longTemp;
      let address = await getAddress(lat, lang);
      getLocationData(lat, lang, address);
      getCoordinates(lat, lang, 10);
      setLat(lat);
      setLong(lang);
      setZoom(12);
      setLoaded(true);
      setSLoaded(true);
    } else {
      setShowWarning(false);
    }
  }, [apiData.workshopFilterMap]);

  useEffect(() => {
    if (globalData?.locationData) {
      getCoordinates(
        globalData.locationData?.lat,
        globalData.locationData?.lng,
        10
      );
    }
  }, [globalData?.locationData]);

  const getCurrentLocation = () => {
    locateCurrentPosition()
      .then((position) => {
        const lat = Number(position.coords.latitude);
        const lang = Number(position.coords.longitude);
        setLat(Number(lat));
        setLong(Number(lang));
        onPlaceChangedNew(lat, lang);
        getLocationData(lat, lang, "");
      })
      .catch((e) => {
        GetInfo(getInfoCallback);
      });
  };

  const getInfoCallback = (lat, lang) => {
    setLat(Number(lat));
    setLong(Number(lang));
    onPlaceChangedNew(lat, lang);
    getCoordinates(lat, lang, 10);
    onPlaceChangedNew(
      place.geometry.location.lat(),
      place.geometry.location.lng(),
      place.formatted_address
    );
  };

  const locateCurrentPosition = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        }
      );
    });

  const onPlaceChangedNew = async (lats, longs, haveAddress = false) => {
    try {
      const lat = Number(lats);
      const lang = Number(longs);
      const size = 10;

      if (
        cart.products.length > 0 &&
        router.pathname == "/services/[...slug]"
      ) {
        let ids = cart.products.map((item) => item.Id);

        await getWorkshopFilterMapMultiple(
          10,
          1,
          lat,
          lang,
          ids,
          globalData.carData?.modelId,
          "Distance"
        );
        setLatTemp(lat);
        setLongTemp(lang);
      } else {
        let address = haveAddress ? haveAddress : await getAddress(lat, lang);
        setLocation(address);
        getLocationData(lat, lang, address);
        getCoordinates(lat, lang, size);
        setLat(lat);
        setLong(lang);
        setZoom(12);
        setLoaded(true);
        setSLoaded(true);
        handleToggleLocation();
      }
    } catch (error) {
      console.log(error);
    }
    return;
  };

  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`
      );
      const jsonData = response.data;
      return jsonData.plus_code?.compound_code;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleLocation = () => {
    setShowvalues(!showvalues);
    getCurrentLocation();
  };

  const handleRemoveLocation = () => {
    setLocationText("");
  };

  const handleSaveLocation = () => {
    if (!locationText) {
      return;
    }
    setSaveLocation(true);
  };

  const handleManagelocation = () => {
    if (router.pathname == "/profile/my-address") {
      handleToggleLocation();
      return;
    }
    setLoader(true);
    router.push("/profile/my-address");
  };

  const handleChangeLocation = () => {
    setSaveLocation(false);
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        className={`dropdown ${handleClasses("cr-loca-popup")}`}
      >
        <div
          onClick={() => {
            if (!currentLocation?.EmirateArea) {
              return;
            }
            handleToggleLocation();
          }}
          className={handleClasses("cr-location")}
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>Your Car Location</span>
          <strong>
            {currentLocation?.EmirateArea
              ? currentLocation?.EmirateArea?.substring(0, 48)
              : "Fetching..."}
          </strong>
          <i className="fa-solid fa-caret-down" />
        </div>
        {globalData.locationForm ? (
          <div
            className={`dropdown-menu ${handleClasses(
              "dropdown-menu car-loc-dropdown"
            )} show`}
            aria-labelledby="dropdownMenuButton"
          >
            <div className={handleClasses("outer-cr-sl-skin")}>
              <div className={handleClasses("location-dr-bl")}>
                <div
                  className={handleClasses(
                    globalData.saveLocation
                      ? "new-loc-block hide"
                      : "new-loc-block"
                  )}
                >
                  <h5>Your Location</h5>
                  <div className={handleClasses("location-dr-input")}>
                    <span
                      onClick={() => handleLocation()}
                      className={handleClasses("gps-icon")}
                    >
                      <img src="/assets/header/gps.svg" alt="gps" />
                    </span>
                    <span
                      onClick={() => handleRemoveLocation()}
                      className={handleClasses("cross-icon")}
                    >
                      <img src="/assets/header/cross.svg" alt="cross" />
                    </span>
                    <Autocomplete
                      className="form-control ellip"
                      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}
                      style={{
                        width: "100%",
                        padding: "0px 10px",
                        borderColor: "#ced4da",
                      }}
                      onPlaceSelected={(place) => {
                        setLocationText(place.formatted_address);
                        console.log(
                          "sdfjsdlfjksdfljksdf",
                          place.formatted_address,
                          place.geometry
                        );
                        onPlaceChangedNew(
                          place.geometry.location.lat(),
                          place.geometry.location.lng(),
                          place.formatted_address
                        );
                        setDropDownSelection(true);
                      }}
                      options={{
                        types: [],
                        componentRestrictions: { country: "ae" },
                      }}
                      value={locationText}
                      onChange={(e) => {
                        setLocationText(e.target.value);
                      }}
                      // defaultValue={locationText}
                    />
                  </div>
                  {login ? (
                    <div className={handleClasses("location-dr-action")}>
                      <button
                        onClick={() => handleSaveLocation()}
                        className={handleClasses("sv-add")}
                        disabled={!apiData?.coordinates[0]?.AreaName}
                      >
                        Save Address
                      </button>
                    </div>
                  ) : null}
                </div>
                <div
                  className={handleClasses(
                    globalData.saveLocation && login
                      ? "new-loc-block"
                      : "new-loc-block hide"
                  )}
                >
                  <h5>Saved Location</h5>
                  <div className={handleClasses("saved-lc-block")}>
                    <i className={handleClasses("fa-solid fa-location-dot")} />
                    <span>
                      {locationText
                        ? locationText
                        : "International Media Production Zone"}
                    </span>
                  </div>
                  <div className={handleClasses("location-dr-action")}>
                    <button
                      onClick={() => handleManagelocation()}
                      className={handleClasses("sv-add")}
                    >
                      Manage Address
                    </button>
                    <button
                      style={{ marginLeft: "5px" }}
                      onClick={() => handleChangeLocation()}
                      className={handleClasses("sv-add")}
                    >
                      Change location
                    </button>
                  </div>
                </div>
                <div className={handleClasses("drop-d-n")}>
                  <img
                    src="/assets/images/workshop_location.webp"
                    alt="ob-location"
                    style={{ height: "26px" }}
                  />
                  <h5>Nearest OB Workshop Location</h5>
                </div>
                {apiData.coordinates != "" &&
                apiData.coordinates != null &&
                apiData.coordinates.length !== 0 ? (
                  <div className={handleClasses("nearest-dr")}>
                    <div className={handleClasses("nr-lf")}>
                      <h6 className={handleClasses("ellipsis")}>
                        {apiData.coordinates != "" &&
                        apiData.coordinates != null &&
                        apiData.coordinates.length !== 0
                          ? apiData.coordinates[0].AreaName
                          : "Not Found"}
                      </h6>
                      <span>
                        {apiData.coordinates != "" &&
                        apiData.coordinates != null &&
                        apiData.coordinates.length !== 0
                          ? apiData.coordinates[0].Name
                          : "Not Found"}
                      </span>
                      <strong>
                        <i
                          className={handleClasses("fa-solid fa-location-dot")}
                        />{" "}
                        Distance :
                        {parseFloat(
                          apiData.coordinates != "" &&
                            apiData.coordinates != null &&
                            apiData.coordinates.length !== 0
                            ? apiData.coordinates[0].Distance
                            : 0
                        ).toFixed(0)}{" "}
                        KM
                      </strong>
                    </div>
                    {apiData.coordinates != "" &&
                    apiData.coordinates != null &&
                    apiData.coordinates.length !== 0 ? (
                      <div className={handleClasses("nr-rght")}>
                        <img
                          src={apiData.coordinates[0].CoverImageUrl}
                          alt="CoverImage"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <p style={{ fontSize: "14px" }} className="px-2">
                    No workshop found on your car location
                    {/* Unable to locate any workshops at your car's current location. */}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartProducts: (products) => {
      dispatch(setCartProducts(products));
    },
    setCartJobItems: (jobs) => {
      dispatch(setCartJobItems(jobs));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    getCoordinates: (lat, lang, size) => {
      dispatch(getCoordinates(lat, lang, size));
    },
    getLocationData: (lat, lng, address) => {
      dispatch(getLocationData(lat, lng, address));
    },
    setAccessLocation: (lat, lng, address) => {
      dispatch(setAccessLocation(lat, lng, address));
    },
    setSaveLocation: (data) => {
      dispatch(setSaveLocation(data));
    },
    getWorkshopFilterMapMultiple: (
      size,
      page,
      lat,
      lang,
      vIds,
      mId,
      filter
    ) => {
      dispatch(
        getWorkshopFilterMapMultiple(size, page, lat, lang, vIds, mId, filter)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSection);
