import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCoordinates,
  getWorkshopFilterMapMultiple,
} from "../../redux/actions/apiActions";
import { getLocationData } from "../../redux/actions/globalAction";
import { usePlacesWidget } from "react-google-autocomplete";
import {  FaTimes } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import {
  setCartJobItems,
  setCartProducts,
  setCartStage,
  setCartWorkshop,
} from "../../redux/actions/cartAction";
import OutsideClickHandler from "react-outside-click-handler";
import { GetInfo } from "lib/Location";
import axios from "axios";

function ChangeLocation({
  getCoordinates,
  getLocationData,
  apiData,
  setShowLocation,
  cart,
  globalData,
  getWorkshopFilterMapMultiple,
  setCartStage,
  setCartProducts,
  setCartWorkshop,
  setCartJobItems,
}) {
  const [map, setMap] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [sLoaded, setSLoaded] = useState(false);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [latTemp, setLatTemp] = useState(0);
  const [longTemp, setLongTemp] = useState(0);
  const [zoom, setZoom] = useState(12);
  const [autocompleted, setAutocompleted] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [location, setLocation] = useState("");
  const [locationText, setLocationText] = useState("");
  const [dropDownSelection, setDropDownSelection] = useState(false);

  useEffect(() => {
    if (!globalData.locationData.data) {
      getCurrentLocation();
    } else {
      setLocation(globalData.locationData.data.EmirateArea);
    }
  }, []);

  useEffect(() => {
    if (dropDownSelection) {
      setDropDownSelection(false);
      return;
    }
    if (globalData.locationData?.data) {
      setLocationText(globalData.locationData.data.EmirateArea);
    }
  }, [globalData.locationData?.data]);

  useEffect(async () => {
    let result =
      apiData.workshopFilterMap?.filter((item) => item.Id === cart.workshop?.Id)
        .length === 1;
    if (result) {
      let lat = latTemp;
      let lang = longTemp;
      let address = await getAddress(lat, lang);
      // setLocation(address);
      getLocationData(lat, lang, address);
      getCoordinates(lat, lang, 10);
      setLat(lat);
      setLong(lang);
      setZoom(12);
      setLoaded(true);
      setSLoaded(true);
    } else {
      setShowWarning(false);
    }
  }, [apiData.workshopFilterMap]);

  //   const { ref, autocompleteRef } = usePlacesWidget({
  //     apiKey: process.env.NEXT_PUBLIC_API_URL,
  //     onPlaceSelected: (place) => {
  //     },
  //   });

  const { ref } = usePlacesWidget({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      setDropDownSelection(true);
      setLocationText(place.formatted_address);
      onPlaceChangedNew(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        place.formatted_address
      );
    },
    options: {
      types: [],
      componentRestrictions: { country: "AE" },
    },
  });

  const getCurrentLocation = () => {
    // const hasLocationPermission = localStorage.getItem('hasLocationPermission');
    // if (hasLocationPermission != "granted")
    locateCurrentPosition()
      .then((position) => {
        const lat = Number(position.coords.latitude);
        const lang = Number(position.coords.longitude);
        setLat(Number(lat));
        setLong(Number(lang));
        onPlaceChangedNew(lat, lang);
      })
      .catch((e) => {
        GetInfo(getinfoCallback);
      });
  };

  const getinfoCallback = async (lat, lang) => {
    setLat(Number(lat));
    setLong(Number(lang));
    onPlaceChangedNew(lat, lang);
    getCoordinates(lat, lang, 10);
  };

  const getinfoErrorCallback = (lat, lng) => {
    setLoaded(false);
  };

  const locateCurrentPosition = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        }
      );
    });

  const onPlaceChangedNew = async (lats, longs, haveAddress = false) => {
    try {
      const lat = Number(lats);
      const lang = Number(longs);
      const size = 10;

      if (cart.products.length > 0) {
        let ids = cart.products.map((item) => item.Id);
        await getWorkshopFilterMapMultiple(
          10,
          1,
          lat,
          lang,
          ids,
          globalData.carData?.modelId,
          "Distance"
        );
        setLatTemp(lat);
        setLongTemp(lang);
        // result =
        //   apiData.workshopFilterMap.filter(
        //     (item) => item.Id === cart.workshop?.Id
        //   ).length === 1;
      } else {
        let address = haveAddress ? haveAddress : await getAddress(lat, lang);
        setLocation(address);
        getLocationData(lat, lang, address);
        getCoordinates(lat, lang, size);
        setLat(lat);
        setLong(lang);
        setZoom(12);
        setLoaded(true);
        setSLoaded(true);
      }
    } catch (error) {
      console.log(error);
      //   getCurrentLocation();
    }
    return;
  };

  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`);
      const jsonData = response.data;
      return jsonData.plus_code?.compound_code;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const scriptLoad = () => {
    setSLoaded(true);
  };

  const onLoading = (autocomplete) => {
    setAutocompleted(autocomplete);
  };

  const handleConfirmChange = () => {
    setCartStage(1);
    setCartProducts([]);
    setCartJobItems([]);
    setCartWorkshop(null);
    setShowWarning(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setShowLocation(false)}>
      <div className="change-location">
        <Modal show={showWarning} onHide={() => setShowWarning(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Change Workshop</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you want change workshop? Items in cart will be removed from
              cart!
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowWarning(false)}>
              No
            </Button>
            <Button variant="primary" onClick={handleConfirmChange}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <span className="close-btn" onClick={() => setShowLocation(false)}>
          <FaTimes />{" "}
        </span>
        <div className="rowMap location-change-popup">
          <div className="ml-3 mr-1 mt-4 w-25">
            <img
              src="/assets/images/my_car.svg"
              alt="logo"
              className="location-image"
              width="30px"
            />
          </div>
          <div className="columnMap p-2 mt-3 pb-4 pr-3 w-75">
            <div className="text-secondary location-header">Your Location</div>
            <div className="rowMap align-items-center bBorder ">
              <input
                type="text"
                placeholder="Search Location"
                className="inputMap"
                ref={ref}
                value={locationText}
                // value={location}
                // onChange={(e) => setLocation(e.target.value)}
                onChange={(e) => setLocationText(e.target.value)}
              />
              <a className="" onClick={getCurrentLocation}>
                <img
                  src="/assets/images/target.svg"
                  alt="logo"
                  width={25}
                  className="location-target"
                />
              </a>
            </div>
            <small className="text-secondary">Change Location</small>
          </div>
        </div>
        <div className="rowMap workshop-area">
          <div className="m-3">
            <img
              src="/assets/images/workshop_location.svg"
              alt="logo"
              className="location-image"
            />
          </div>
          <div className="columnMap p-2 mt-2">
            <div className="text-secondary location-header">
              {"Nearest OB Workshop Location"}
            </div>
            <div className="mt-2 inputMap">
              {apiData.coordinates != "" &&
              apiData.coordinates != null &&
              apiData.coordinates.length !== 0
                ? apiData.coordinates[0].AreaName
                : "Select"}
            </div>
          </div>
        </div>
        {/* {isLoaded && (
        <Autocomplete
          onLoad={onLoading}
          onPlaceChanged={onPlaceChangedNew}
          types={"geocode"}
          restrictions={{ country: ["AE"] }}
        >
          <div>
            <div className="rowMap location-change-popup">
              <div className="m-3 mt-4">
                <img
                  src="/assets/images/my_car.svg"
                  alt="logo"
                  className="location-image"
                />
              </div>
              <div className="columnMap p-2 mt-3">
                <div className="text-secondary location-header">
                  Your Location
                </div>
                <div className="rowMap align-items-center bBorder">
                  <input
                    type="text"
                    placeholder="Search Location"
                    className="inputMap"
                  />
                  <a className="" onClick={getCurrentLocation}>
                    <img
                      src="/assets/images/target.svg"
                      alt="logo"
                      width={25}
                      className="location-target"
                    />
                  </a>
                </div>
                <div
                  className="mt-2 text-primary location-change"
                  onClick={() => {
                  }}
                >
                  <u>Change Location</u>
                </div>
              </div>
            </div>
          </div>
        </Autocomplete>
      )} */}
      </div>
    </OutsideClickHandler>
  );
}

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartProducts: (products) => {
      dispatch(setCartProducts(products));
    },
    setCartJobItems: (jobs) => {
      dispatch(setCartJobItems(jobs));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    getCoordinates: (lat, lang, size) => {
      dispatch(getCoordinates(lat, lang, size));
    },
    getLocationData: (lat, lng, address) => {
      dispatch(getLocationData(lat, lng, address));
    },
    getWorkshopFilterMapMultiple: (
      size,
      page,
      lat,
      lang,
      vIds,
      mId,
      filter
    ) => {
      dispatch(
        getWorkshopFilterMapMultiple(size, page, lat, lang, vIds, mId, filter)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLocation);
