import axios from "axios";
import { toast } from "react-hot-toast";

export function saveCart(cart, apiData, globalData, emptyCart = false) {
  let cartId = localStorage.getItem("cartId");
  const storedCarData = localStorage.getItem('car_data')
  let data = {
    // CarId: null,
    Id: cartId ? cartId : null,
    CarModelId : storedCarData ? JSON.parse(storedCarData).modelId : globalData?.carData?.modelId || null

    // CarModelId: localStorage.getItem('car_data') ? JSON.parse(localStorage.getItem('car_data'))?.modelId : (globalData?.carData?.modelId || null),
    // CarPlateNo: "PlateNo",
    // CarChassisNo: "ChassisNo",
  };

  if (cart.addMembership) {
    data = emptyCart
      ? data
      : {
        ...data,
        MembershipId: apiData.levelupMembership.Id,
        MembershipOriginalCost: apiData.levelupMembership.OriginalCost,
        MembershipCost: apiData.levelupMembership.Cost,
      };
  }

  data.Jobs = emptyCart
    ? []
    : cart.jobItems.map((job) => {
      const brandId = localStorage.getItem("brand-id");
      return {
        JobItemId: job.JobItemId,
        cost: job.Cost,
        JourneyVariantId: job.JourneyVariantId,
        JourneyVariantItemId: job.JourneyVariantItemId,
        WorkshopId: job.WorkshopId,
        JobId: job.JobId,
        OriginalCost: job.OriginalCost,
        BrandId: brandId
      };
    });

  data.Addons = emptyCart
    ? []
    : cart.addedAddons.map((addon) => {
      return {
        AddonId: addon.Id,
        originalCost: addon.Cost,
        Cost: addon.Cost,
        AddonItemId: addon.AddonItemId,
        WorkshopAddonId: addon.WorkshopAddonId,
        WorkshopAddonItemId: addon.WorkshopAddonItemId,
      };
    });

  data.Packages = emptyCart
    ? []
    : cart.addedPackages.map((packageItem) => {
      return {
        Id: packageItem.Id,
        originalCost: packageItem.Cost,
        Cost: packageItem.Cost,
      };
    });

  let token = JSON.parse(localStorage.getItem("login_access"));
  // if (token?.access_token) {
  axios
    .post(`${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-carts`, data, {
      headers: token ? {
        Authorization: "bearer " + token?.access_token,
      } : {},
    })
    .then((res) => {
      localStorage.setItem("cartId", res?.data?.Data?.Id);
    })
    .catch((err) => {
      console.log("Error ", err);
      // toast.error("Something went wrong!");
    });
  // }
}

export async function saveCartJobs(
  cart,
  apiData,
  globalData,
  jobItems,
  emptyCart = false
) {
  let cartId = localStorage.getItem("cartId");
  let data = {
    Id: cartId ? cartId : null,
    // CarId: null,
    CarModelId: globalData.carData.modelId,
    // CarPlateNo: "PlateNo",
    // CarChassisNo: "ChassisNo",
  };

  if (cart.addMembership) {
    data = emptyCart
      ? data
      : {
        ...data,
        MembershipId: apiData.levelupMembership.Id,
        MembershipOriginalCost: apiData.levelupMembership.OriginalCost,
        MembershipCost: apiData.levelupMembership.Cost,
      };
  }

  data.Jobs = emptyCart
    ? []
    : jobItems.map((job) => {
      return {
        JobItemId: job.JobItemId,
        cost: job.Cost,
        JourneyVariantId: job.JourneyVariantId,
        JourneyVariantItemId: job.JourneyVariantItemId,
        WorkshopId: job.WorkshopId,
        JobId: job.JobId,
        OriginalCost: job.OriginalCost,
      };
    });

  data.Addons = emptyCart
    ? []
    : cart.addedAddons.map((addon) => {
      return {
        AddonId: addon.Id,
        originalCost: addon.Cost,
        Cost: addon.Cost,
        AddonItemId: addon.AddonItemId,
        WorkshopAddonId: addon.WorkshopAddonId,
        WorkshopAddonItemId: addon.WorkshopAddonItemId,
      };
    });

  data.Packages = emptyCart
    ? []
    : cart.addedPackages.map((packageItem) => {
      return {
        Id: packageItem.Id,
        originalCost: packageItem.Cost,
        Cost: packageItem.Cost,
      };
    });

  let token = JSON.parse(localStorage.getItem("login_access"));
  // if (token?.access_token) {
  const res = await axios
    .post(`${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-carts`, data, {
      headers: token ? {
        Authorization: "bearer " + token?.access_token,
      } : {},
    })
  if (res.data) {
    localStorage.setItem("cartId", res?.data?.Data?.Id);
  } else {
    console.log("error", res.data)
  }
}

export function saveCartAddons(
  cart,
  apiData,
  globalData,
  addons,
  emptyCart = false
) {
  let cartId = localStorage.getItem("cartId");
  let data = {
    Id: cartId ? cartId : null,
    // CarId: null,
    CarModelId: globalData.carData.modelId,
    // CarPlateNo: "PlateNo",
    // CarChassisNo: "ChassisNo",
  };

  if (cart.addMembership) {
    data = emptyCart
      ? data
      : {
        ...data,
        MembershipId: apiData.levelupMembership.Id,
        MembershipOriginalCost: apiData.levelupMembership.OriginalCost,
        MembershipCost: apiData.levelupMembership.Cost,
      };
  }

  data.Jobs = emptyCart
    ? []
    : cart.jobItems.map((job) => {
      return {
        JobItemId: job.JobItemId,
        cost: job.Cost,
        JourneyVariantId: job.JourneyVariantId,
        JourneyVariantItemId: job.JourneyVariantItemId,
        WorkshopId: job.WorkshopId,
        JobId: job.JobId,
        OriginalCost: job.OriginalCost,
      };
    });

  data.Addons = emptyCart
    ? []
    : addons.map((addon) => {
      return {
        AddonId: addon.Id,
        originalCost: addon.Cost,
        Cost: addon.Cost,
        AddonItemId: addon.AddonItemId,
        WorkshopAddonId: addon.WorkshopAddonId,
        WorkshopAddonItemId: addon.WorkshopAddonItemId,
      };
    });

  data.Packages = emptyCart
    ? []
    : cart.addedPackages.map((packageItem) => {
      return {
        Id: packageItem.Id,
        originalCost: packageItem.Cost,
        Cost: packageItem.Cost,
      };
    });

  let token = JSON.parse(localStorage.getItem("login_access"));
  // if (token?.access_token) {
  axios
    .post(`${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-carts`, data, {
      headers: token ? {
        Authorization: "bearer " + token?.access_token,
      } : {},
    })
    .then((res) => {
      localStorage.setItem("cartId", res?.data?.Data?.Id);
    })
    .catch((err) => {
      console.log("Error ", err);
      // toast.error("Something went wrong!");
    });
  // }
}

export async function fetchCart() {
  try {
    let cartId = localStorage.getItem("cartId");
    var Id = cartId ? cartId : null;
    let token = JSON.parse(localStorage.getItem("login_access"));
    if (token?.access_token) {
      let { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-carts?Id=${Id || null}`,
        {
          headers: {
            Authorization: "bearer " + token?.access_token,
          },
        }
      );
      if (data) {
        localStorage.setItem("cartId", data?.Data?.Id);
      }
      return data;
    } else {
      let { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-carts?Id=${Id || null}`,
      );
      if (data) {
        localStorage.setItem("cartId", data?.Data?.Id);
      }
      return data;
    }
  } catch (error) {
    console.log("cart error ", error);
  }
}

export async function resetCart() {
  try {
    sessionStorage.removeItem("get-fitting-at-home");
    let token = JSON.parse(localStorage.getItem("login_access") || JSON.stringify(""));
    let cartId = localStorage.getItem("cartId");
    const req = {
      Id: cartId ? cartId : null,
      CarModelId: 0
    }
    // if (token?.access_token) {
    let { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/customer-carts/reset
        `, req,
      {
        headers: {
          Authorization: "bearer " + token?.access_token,
        },
      }
    );
    // }
  } catch (error) {
    console.log("error ------------------", error);

  }
}
