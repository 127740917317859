import { useState, useEffect } from "react";
import Link from "next/link";
import Nav from "react-bootstrap/Nav";
import { connect, useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { isMobile } from "react-device-detect";
import { getModel } from "../../redux/actions/apiActions";
import useWindowDimensions from "../WindowSize";
import ChooseCar from "./ChooseCar";
import { IoIosArrowBack } from "react-icons/io";
import {
  getCarBrandModal,
  fetchLocationData,
  getLocationData,
  setLoginPopup,
  setCarPopup,
  setLoader,
  toggleSignupForm,
  toggleLoginForm,
  toggleChooseCar,
} from "../../redux/actions/globalAction";
import Location from "./Location";
import {
  clearLoginData,
  getUserAuth,
  setAuth,
} from "../../redux/actions/loginAction";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import MyAccount from "./MyAccount";
import {
  FaCar,
  FaHome,
  FaInfo,
  FaInfoCircle,
  FaListUl,
  FaMobileAlt,
  FaQuestion,
  FaShoppingCart,
  FaTimes,
  FaTruckPickup,
  FaUserEdit,
} from "react-icons/fa";
import Notification from "./Notification";
import { fetchCars } from "../../redux/actions/profileAction";
import TourFlow from "../common/TourFlow";
import ChooseCarMobile from "../common/ChooseCarMobile";
import { useRouter } from "next/router";
import {
  setCartAddons,
  setCartStage,
  setCartJobItems,
  setCartProducts,
  setCartWorkshop,
  setShowMobileIstand,
} from "../../redux/actions/cartAction";
import SearchHead from "./SearchHead";
import Image from "next/image";
import SearchHeadMobile from "./SearchHeadMobile";
import {
  gtmCall,
  gtmWhatsApp,
} from "../../lib/google-tag-manager";
import { fetchBrand, fetchCustomerCart } from "../../actions/homePage";
import IMAGES from "../../images";
import { fetchCart } from "../../lib/cart";
import { Spinner } from "react-bootstrap";
import { GetInfo } from "lib/Location";
import UserAuthentication from "../auth/index";

const steps = [
  {
    selector: ".tour-1",
    content: (
      <>
        <strong>Tip:</strong> Please choose your car to view prices
      </>
    ),
    position: "bottom",
  },
];

const Header = ({
  carData,
  loginData,
  locationOn,
  getModel,
  apiData,
  getCarBrandModal,
  globalData,
  getUserAuth,
  setAuth,
  fetchLocationData,
  getLocationData,
  setLoginPopup,
  fetchCars,
  profile,
  cart,
  setCartStage,
  setShowMobileIstand,
  askLocation = false,
  setLoader,
  toggleLoginForm,
  toggleSignupForm,
  toggleChooseCar,
}) => {
  const { height, width } = useWindowDimensions();
  const [hide, setHide] = useState(false);
  const [carHide, setCarHide] = useState(false);
  const [logHide, setLogHide] = useState(false);
  const [login, setLogin] = useState(false);
  const [bellH, setBellH] = useState(false);
  const [ob, setOb] = useState("");
  const onBlur = () => setCarHide(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [showCarSelect, setShowCarSelect] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm();
  const [verifyOtpSection, setVerifyOtpSection] = useState(false);
  const [authBoxType, setAuthBoxType] = useState("login");

  const onMyAccountClick = () => setShowMyAccount(!showMyAccount);

  const hideHeader =
    router.pathname == "/request" || router.pathname == "/offer-inquiry";
  const isCheckout = router.pathname == "/checkout";

  useEffect(async () => {
    getUserAuth();
    await fetchLocationData();
  }, []);

  useEffect(() => {
    if (askLocation) {
      getCurrentLocation();
    }
  }, [askLocation]);

  useEffect(() => {
    if (globalData.showLoginPop) {
      setLogHide(globalData.showLoginPop);
      setAuthBoxType("login");
    }
    // setLoginPopup(false)
  }, [globalData.showLoginPop]);

  useEffect(async () => {
    if (
      Array.isArray(globalData.carData) &&
      !localStorage.getItem("car_data") &&
      profile.cars.length > 0 &&
      !(cart.workshop && cart.products)
    ) {
      let carData = profile.cars[0];
      getCarBrandModal(
        carData.CarCompany.CompanyName,
        carData.CarModel.Name,
        carData.CarCompany.ImgUrl,
        carData.CarModel.ImgUrl,
        carData.CarCompany.Id,
        carData.CarModel.Id,
        carData.CarModel.Slug
      );
    }
  }, [profile.cars]);

  const getCurrentLocation = () => {
    const hasLocationPermission = localStorage.getItem("hasLocationPermission");
    if (hasLocationPermission != "granted") {
      locateCurrentPosition()
        .then((position) => {
          const lat = Number(position.coords.latitude);
          const lang = Number(position.coords.longitude);
          getLocationData(lat, lang, "");
        })
        .catch((e) => {
          GetInfo(getinfoCallback);
        });
    }
  };

  const getinfoCallback = (lat, lang) => {
    getLocationData(lat, lang, "");
  };

  const locateCurrentPosition = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          localStorage.setItem("hasLocationPermission", "granted");
          resolve(position);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        }
      );
    });

  useEffect(() => {
    const header = document.querySelector(".header-wrap");
    dispatch(fetchBrand());
    setLogin(
      loginData.login != null &&
        loginData.login != undefined &&
        loginData.login != ""
        ? true
        : false
    );
    setOb(loginData.userAuth?.ObPointAmount || 0);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loginData]);

  const handleScroll = () => { };

  useEffect(async () => {
    const isCartClear = localStorage.getItem("isCartClear");
    if (
      loginData &&
      loginData.userAuth &&
      (!isCheckout || localStorage.getItem("fetch-cart")) &&
      !isCartClear
    ) {
      localStorage.removeItem("headerLogin");
      localStorage.removeItem("isCartClear");
      dispatch(fetchCustomerCart());
    }
  }, [loginData]);

  // useEffect(async () => {
  //   console.log("first ----", loginData);
  //   console.log("is checkout ---", isCheckout)
  //   console.log("third ---", localStorage.getItem('fetch-cart'))
  //   if (loginData && loginData.userAuth && (!isCheckout || localStorage.getItem('fetch-cart'))) {
  //     alert("fix")
  //     let oldCart = await fetchCart();
  //     console.log("old cart ----------", oldCart)
  //     const data = JSON.parse(
  //       localStorage.getItem("car_data") || JSON.stringify("")
  //     );
  //     if ((localStorage.getItem("headerLogin") || localStorage.getItem('fetch-cart')) && oldCart?.Data && (oldCart.Data?.jobs?.length || oldCart.Data?.workshop || oldCart.Data?.product?.length)) {
  //       localStorage.removeItem("headerLogin")
  //       setCartJobItems(oldCart.Data.jobs);
  //       setCartWorkshop(oldCart.Data.workshop);
  //       setCartProducts(oldCart.Data.product);
  //       setCartAddons(oldCart.Data.addons);
  //       if (oldCart.Data?.CarDetails) {
  //         getCarBrandModal(
  //           oldCart.Data?.CarDetails?.CarCompanyName,
  //           oldCart.Data?.CarDetails?.CarModelName,
  //           oldCart.Data?.CarDetails?.CarCompany.ImgUrl,
  //           oldCart.Data?.CarDetails?.CarModel.ImgUrl,
  //           oldCart.Data?.CarDetails?.CarCompany.ID,
  //           oldCart.Data?.CarDetails?.CarModel.ID,
  //           data && data.slug ? data.slug : null
  //         );
  //       }
  //       localStorage.removeItem('fetch-cart')
  //     }
  //     if ((!oldCart?.Data || ((oldCart?.Data.product && !oldCart?.Data.product.length) || (oldCart?.Data.jobs && !oldCart?.Data.jobs.length))) && localStorage.getItem('fetch-cart')) {
  //       localStorage.removeItem('fetch-cart')
  //       router.push("/services")
  //     }
  //   }
  // }, [loginData]);

  const hideSearchFlow = router.pathname === "/checkout";

  let productLink = "";

  if (cart.products.length > 0) {
    productLink = apiData.categories.filter(
      (item) => item.ID == cart.products[0].JourneyCategoryId
    )[0].Slug;
  }
  const goback = () => {
    setLoading(true);
    setShowMobileIstand(true);
    localStorage.setItem("isHeader", true);
    router.push(
      `/services/fujairah-mina-al-fajer/${productLink}`
    );
  }

  return (
    <header className={`header-wrap header-wrap--transparent is-sticky`}>
      {showSearch ? (
        <Nav className="header" onBlur={onBlur}>
          <SearchHeadMobile onClose={() => setShowSearch(false)} />
        </Nav>
      ) : (
        <Nav className="header" onBlur={onBlur}>
          {isCheckout ? (
            <Nav.Item>
              <Nav.Link className="d-flex justify-content-center align-items-center">
                <div className="">
                  <IoIosArrowBack
                    style={{ fontSize: "16px", marginTop: "-1px" }}
                  />
                  <span
                    className=""
                    style={{ fontSize: "14px" }}
                    onClick={goback}
                  // onClick={() => {
                  //   setLoading(true);
                  //   setCartStage(4)

                  //   setShowMobileIstand(true);
                  //   localStorage.setItem("isHeader", true);
                  //   router.push(
                  //     `/services/fujairah-mina-al-fajer/${productLink}`
                  //   );
                  // }}
                  >
                    {isMobile ? "Go Back " : "Go Back to Cart "}
                    {loading ? (
                      <Spinner
                        animation="grow"
                        role="status"
                        className="ml-2"
                        size="sm"
                      // style={{ width: "15px", height: "15px" }}
                      >
                        <span className="visually-hidden"></span>
                      </Spinner>
                    ) : null}
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
          ) : null}
          <Nav.Item
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Nav.Item
              className={
                isCheckout
                  ? "d-md-flex justify-content-center align-items-center"
                  : ""
              }
            >
              <Nav.Link
                className={`center-header ${isCheckout ? (isMobile ? "pb-0" : "pr-0") : ""
                  }`}
              >
                <Link href="/">
                  <a onClick={() => localStorage.removeItem("isWorkshop")}>
                    <img
                      // src={IMAGES.LOGO}
                      src="/assets/Logo12.webp"
                      width={isMobile ? 120 : 150}
                      className="p-1 p-sm-2 nav-brand-logo"
                      alt="logo"
                    />
                  </a>
                </Link>
              </Nav.Link>
              {isCheckout ? (
                <p
                  className="font-weight-bold text-center"
                  style={{
                    fontWeight: "600!important",
                    fontSize: isMobile ? "16px" : "18px",
                  }}
                >
                  Secure Checkout
                </p>
              ) : null}
            </Nav.Item>
            {!isMobile && width > 850 && !hideHeader && !isCheckout && (
              <Nav.Item>
                <Nav.Link
                  className="center-header whatsapp"
                  href="tel:8002274357"
                  target="_blank"
                >
                  <a
                    onClick={() => gtmCall()}
                    className=" align-items-center phoneP d-flex"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Image
                      src={IMAGES.PHONE}
                      alt="logo"
                      width={35}
                      height={36}
                    />
                    <span
                      className="phone ml-2 text-black"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      +971 8002274357
                    </span>
                  </a>
                </Nav.Link>
              </Nav.Item>
            )}
            {!isMobile && width > 850 && !hideHeader && !isCheckout && (
              <Nav.Item>
                {/* <Link href="https://api.whatsapp.com/send?phone=978002274357"> */}
                <Nav.Link
                  className="center-header whatsapp"
                  href="https://api.whatsapp.com/send?phone=978002274357"
                  target="_blank"
                >
                  <a
                    className=" align-items-center phoneP d-flex"
                    onClick={() => gtmWhatsApp()}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Image
                      src={IMAGES.WHATSAPP}
                      alt="logo"
                      width={35}
                      height={36}
                    />
                    <span
                      className="phone ml-2 text-black"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      +971 8002274357
                    </span>
                  </a>
                </Nav.Link>
                {/* </Link> */}
              </Nav.Item>
            )}
          </Nav.Item>
          {!hideSearchFlow && (
            <Nav.Item>{!hideHeader ? <SearchHead /> : null}</Nav.Item>
          )}

          <Nav.Item
            className="hide-in-mobile"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {(cart.products &&
              !isCheckout &&
              !hideHeader &&
              cart.products?.length) ||
              (cart.addedPackages &&
                cart.addedPackages?.length &&
                router.pathname != "/services/[...slug]" &&
                router.pathname != "/checkout/thank-you") ? (
              <Nav.Item
                style={{
                  border: "2px solid #ff3201",
                  padding: 1,
                  borderRadius: 25,
                }}
              >
                <a
                  onClick={() => {
                    setLoader(true);
                    if (router.pathname == "/services/[...slug]") {
                      setTimeout(() => {
                        setLoader(false);
                      }, 2000);
                      return;
                    }
                    localStorage.setItem("isHeader", true);
                    router.push(
                      `/services/fujairah-mina-al-fajer/${productLink}`
                    );
                  }}
                  className="cart-btn d-flex flex-row align-items-center justify-content-center"
                >
                  <FaShoppingCart />
                </a>
              </Nav.Item>
            ) : null}
            {locationOn ? (
              <Location
                style={"ob-button your-location-area"}
                text={String(globalData.locationData?.data?.EmirateArea)}
              />
            ) : (
              carData && (
                <>
                  {router && !hideHeader ? (
                    <>
                      {loginData.userAuth && globalData.carData?.brand ? (
                        <Nav.Item>
                          <Nav.Link onClick={() => setShowMyAccount(true)}>
                            <span className={"ob-button"}>
                              {" "}
                              {globalData.carData != null && (
                                <img
                                  src={`${globalData.carData?.brandUrl}`}
                                  width={30}
                                  height={30}
                                  alt="brand"
                                  className="mr-2"
                                  style={{ borderRadius: "40px" }}
                                />
                                // <FaCar className="mr-2" />
                              )}{" "}
                              {globalData.carData != null
                                ? " " +
                                globalData.carData?.brand +
                                " " +
                                globalData.carData?.modal
                                : "Choose Your Car"}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        <ChooseCar
                          textTrue={false}
                          apiData={apiData}
                          getModel={getModel}
                          showModel={carHide}
                          style={"tour-1 border-none"}
                          getCarBrandModal={getCarBrandModal}
                          globalData={globalData}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )
            )}
            {router &&
              !hideHeader &&
              (login ? (
                <Nav.Link
                  className="center-header phonePL d-flex flex-row align-items-center"
                  onClick={onMyAccountClick}
                >
                  <Image
                    src={loginData?.userAuth?.Avatar || IMAGES.USER}
                    width={55}
                    height={55}
                    className="p-2"
                    alt="logo"
                  />
                  <div className="d-flex flex-column">
                    <span className="welcome-font ">Welcome Back</span>
                    <span className="username ">
                      {loginData.userAuth?.FirstName}
                    </span>
                  </div>
                </Nav.Link>
              ) : (
                <Nav.Link className="center-header phonePL d-flex flex-row align-items-center">
                  <img
                    src="/assets/user.svg"
                    className="p-2 "
                    alt="logo"
                    onClick={() => {
                      setHide(false);
                      setLogHide(true);
                      setCarHide(false);
                      setAuthBoxType("login");
                    }}
                  />
                  <span
                    className="ob-login"
                    onClick={() => {
                      toggleLoginForm(true);
                      toggleChooseCar(false);
                      setHide(false);
                      setLogHide(true);
                      setCarHide(false);
                      setAuthBoxType("login");
                    }}
                  >
                    Login{" "}
                  </span>
                  <span className="mx-1 hide-in-mobile">|</span>
                  <span
                    className="ob-login"
                    onClick={() => {
                      toggleSignupForm(true);
                      toggleChooseCar(false);
                      setHide(false);
                      setLogHide(true);
                      setCarHide(false);
                      setAuthBoxType("signup");
                      setVerifyOtpSection(false);
                    }}
                  >
                    Sign Up
                  </span>
                </Nav.Link>
              ))}
            {!isMobile && width > 850 && !isCheckout && !hideHeader && (
              <Nav.Link
                className="phonePL center-header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                {...(login
                  ? { href: "/profile/ob-points" }
                  : {
                    onClick: () =>
                      toast("Login/Sign Up to Accumulate OB CashPoints", {
                        icon: <FaInfoCircle />,
                      }),
                  })}
              // /profile/ob-points
              >
                <Image
                  src={IMAGES.COIN}
                  width={60}
                  height={60}
                  className="p-2"
                  alt="logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="ob-point phoneL">OB CASHPOINTS</span>
                  <span className="ob-value phoneL">
                    {Number(ob).toFixed(0) || 0}
                  </span>
                </div>
              </Nav.Link>
            )}

            {login && !hideHeader && !isCheckout && (
              <Nav.Link
                className="center-header"
                onMouseOver={() => { }}
                onMouseOut={() => { }}
              >
                {/* <img src="/assets/bell.webp" width={55} className={`${bellH ? "p-1" : "p-2"}`} alt="logo"/> */}

                <Dropdown onMouseOver={() => { }} style={{ border: "none" }}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      background: "transparent",
                      border: "none",
                      borderColor: "transparent",
                      padding: "0px",
                      margin: "0px",
                    }}
                    onClick={() => setShowNotification(true)}
                  >
                    <img
                      src="/assets/bell.webp"
                      width={45}
                      className={`p-1 p-sm-2`}
                      alt="logo"
                      style={{
                        border: "3px solid #ff3201",
                        borderRadius: 40,
                      }}
                    />
                  </Dropdown.Toggle>
                </Dropdown>
              </Nav.Link>
            )}
          </Nav.Item>
          <Nav.Item className="menu-bars align-items-center">
            {(cart.products &&
              !hideHeader &&
              !isCheckout &&
              cart.products?.length) ||
              (cart.addedPackages &&
                cart.addedPackages?.length &&
                (router.pathname == "/services/[...slug]" ||
                  router.pathname != "/checkout/thank-you")) ? (
              <Nav.Link className="mr-2 p-1">
                {router.pathname == "/services/[...slug]" ? (
                  <Image
                    src={IMAGES.CART}
                    width={45}
                    height={45}
                    className="p-2"
                    alt="logo"
                    onClick={() => {
                      setLoader(true);
                      setShowMobileIstand(true);
                      if (router.pathname == "/services/[...slug]") {
                        setTimeout(() => {
                          setLoader(false);
                        }, 2000);
                        return;
                      }
                    }}
                    style={{
                      background: "#f3f4ff",
                      borderRadius: "30px",
                    }}
                  />
                ) : (
                  <>
                    {router.pathname != "/checkout/thank-you" &&
                      !isCheckout && (
                        // router.pathname != "/checkout" &&
                        <Link href={`/services/${productLink}`}>
                          <Image
                            src={IMAGES.CART}
                            width={45}
                            height={45}
                            className="p-2"
                            alt="logo"
                            onClick={() => {
                              setShowMobileIstand(true);
                            }}
                            style={{
                              background: "#f3f4ff",
                              borderRadius: "30px",
                            }}
                          />
                        </Link>
                      )}
                  </>
                )}
              </Nav.Link>
            ) : null}
            {!hideSearchFlow && router && !hideHeader ? (
              <Nav.Link className="mr-2 p-1">
                <div
                  className="input-search-mob rowMap align-items-center p-2"
                  onClick={() => setShowSearch(true)}
                >
                  <div
                    className=" d-flex align-items-center"
                    style={{ opacity: 0.5 }}
                  >
                    <Image
                      src={IMAGES.SERACH}
                      alt="logo"
                      width={25}
                      height={25}
                    />
                  </div>
                </div>
              </Nav.Link>
            ) : null}

            {router && !hideHeader && !isCheckout && !cart.showMobileIsland ? (
              <Nav.Link className="mr-2 p-1">
                {globalData.carData?.brandUrl != null ? (
                  <Image
                    src={globalData.carData?.brandUrl || IMAGES.CAR}
                    width={45}
                    height={45}
                    className="p-1"
                    alt="logo"
                    onClick={() => {
                      setShowCarSelect(true);
                    }}
                    style={{
                      background: "#f3f4ff",
                      borderRadius: "30px",
                    }}
                  />
                ) : (
                  <Image
                    src={IMAGES.CAR}
                    width={45}
                    height={45}
                    className="p-2"
                    alt="logo"
                    onClick={() => {
                      setShowCarSelect(true);
                    }}
                    style={{
                      background: "#f3f4ff",
                      borderRadius: "30px",
                    }}
                  />
                )}
              </Nav.Link>
            ) : null}
            <ChooseCarMobile show={showCarSelect} setShow={setShowCarSelect} />
            {router && !hideHeader ? (
              <Nav.Link className="mr-2 p-1">
                <Image
                  src={loginData?.userAuth?.Avatar || IMAGES.USER}
                  width={45}
                  height={45}
                  className="p-0"
                  alt="logo"
                  onClick={() => {
                    if (login) {
                      onMyAccountClick();
                    } else {
                      toggleLoginForm(true);
                      toggleChooseCar(false);
                      setHide(false);
                      setLogHide(!logHide);
                      setCarHide(false);
                      setAuthBoxType("login");
                    }
                  }}
                />
              </Nav.Link>
            ) : null}
            {login && !hideHeader && !isCheckout && (
              <Nav.Link
                className="mr-3 rounded p-0"
              // onClick={() => {
              //   setHide(!hide);
              //   setLogHide(false);
              //   setCarHide(false);
              //   setAuthBoxType("login");
              // }}
              >
                {showNotification ? (
                  <FaTimes
                    style={{
                      height: 45,
                      width: 45,
                      padding: 10,
                    }}
                    onClick={() => setShowNotification(false)}
                  />
                ) : (
                  <Image
                    src={IMAGES.BELL}
                    width={45}
                    className={`p-2`}
                    alt="logo"
                    style={{
                      border: "3px solid #ff3201",
                      borderRadius: 40,
                    }}
                    onClick={() => setShowNotification(true)}
                  />
                )}
              </Nav.Link>
            )}
          </Nav.Item>
        </Nav>
      )}

      {hide && globalData?.chooseCarForm && (
        <>
          <div
            className="bg-layer"
            onClick={() => {
              setHide(false);
              toggleChooseCar(false);
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "85px",
              right: "0px",
              height: "89%",
              width: isMobile ? "75%" : "30%",
              background: "#fff",
              zIndex: 6,
              overflowY: "scroll",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isMobile && (
              // <a
              //   onClick={() => {
              //     setCarHide(!carHide);
              //     setHide(false);
              //     setLogHide(false);
              //   }}
              //   style={{ margin: "10px", marginTop: "25px" }}
              // >
              //   <span className="ob-button">
              //     {carBrand != null && carModal != null
              //       ? carBrand1 + " " + carModal
              //       : "Choose Your Car"}
              //   </span>
              // </a>
              <span>
                {loginData.userAuth ? (
                  <Nav.Item>
                    <Nav.Link onClick={() => setShowMyAccount(true)}>
                      <span className={"ob-button"}>
                        {" "}
                        {globalData.carData != null && (
                          <Image
                            src={`${globalData.carData?.brandUrl}`}
                            width={30}
                            height={30}
                            alt="brand"
                            className="mr-2"
                            style={{ borderRadius: "40px" }}
                          />
                          // <FaCar className="mr-2" />
                        )}{" "}
                        {globalData.carData != null
                          ? " " +
                          globalData.carData?.brand +
                          " " +
                          globalData.carData?.modal
                          : "Choose Your Car"}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  <ChooseCar
                    textTrue={false}
                    apiData={apiData}
                    getModel={getModel}
                    showModel={carHide}
                    style={"ob-button"}
                    getCarBrandModal={getCarBrandModal}
                    globalData={globalData}
                    showMobileBtn
                  />
                )}
              </span>
            )}
            {isMobile && (
              <a
                className="center-header login-head"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Image
                  src={loginData?.userAuth?.Avatar || IMAGES.USER}
                  width={55}
                  height={55}
                  className="p-2"
                  alt="logo"
                />
                {login ? (
                  <div
                    onClick={() => setLogin(false)}
                    className="login-anim"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="ob-point">Welcome Back</span>
                    <span className="ob-name">
                      {loginData.userAuth?.FirstName}
                    </span>
                  </div>
                ) : (
                  <span
                    className="ob-login"
                    onClick={() => {
                      setHide(false);
                      setLogHide(!logHide);
                      setCarHide(false);
                    }}
                  >
                    Login
                  </span>
                )}
              </a>
            )}
            {login && isMobile && (
              <a
                className="center-header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Image
                  src={IMAGES.COIN}
                  width={55}
                  height={55}
                  className="p-2"
                  alt="logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="ob-point">OB POINTS</span>
                  <span className="ob-value">{ob || 0}</span>
                </div>
              </a>
            )}
            <Link href="#home">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaHome width={30} />
                </span>
                <span className="m-2">Home</span>
              </a>
            </Link>
            <Link href="#recommend">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaListUl width={30} />
                </span>
                <span className="m-2">Recommends</span>
              </a>
            </Link>
            <Link href="#review">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaUserEdit width={30} />
                </span>

                <span className="m-2">Review</span>
              </a>
            </Link>
            <Link href="#mechanic">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaTruckPickup width={30} />
                </span>
                <span className="m-2">Mobile Mechanic</span>
              </a>
            </Link>
            <Link href="#rent">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaCar width={30} />
                </span>
                <span className="m-2">Rent</span>
              </a>
            </Link>
            <Link href="#why">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaInfo width={30} />
                </span>
                <span className="m-2">Why OpenBonnet?</span>
              </a>
            </Link>
            <Link href="#download">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaMobileAlt width={30} />
                </span>
                <span className="m-2">Applications</span>
              </a>
            </Link>
            <Link href="#faq">
              <a className=" align-items-center d-flex">
                <span className="menu">
                  <FaQuestion width={30} />
                </span>
                <span className="m-2">FAQs</span>
              </a>
            </Link>
            <div className="d-flex">
              <Link href="/">
                <a className=" align-items-center phoneP p-3 d-flex">
                  <Image src={IMAGES.CALL} alt="logo" width={30} height={30} />
                  <span className="phone">+97112345678</span>
                </a>
              </Link>
              <Link href="/">
                <a className=" align-items-center phoneP-one p-3 d-flex">
                  <Image src={IMAGES.ICON} alt="logo" width={30} height={30} />
                  <span className="phone-one">+97112345678</span>
                </a>
              </Link>
            </div>
          </div>
        </>
      )}

      {logHide ? (
        <UserAuthentication
          setAuthBoxType={setAuthBoxType}
          authBoxType={authBoxType}
          setLogHide={setLogHide}
          setCarHide={setCarHide}
        />
      ) : null}

      {showMyAccount || (cart && cart.showMyAccount) ? (
        <MyAccount
          isMobile={isMobile}
          show={showMyAccount}
          setShow={setShowMyAccount}
        />
      ) : null}
      {showNotification && (
        <Notification
          isMobile={isMobile}
          show={showNotification}
          setShow={setShowNotification}
        />
      )}
      {/* <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      /> */}
      <TourFlow steps={steps} />
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug
        )
      );
    },
    getUserAuth: () => {
      dispatch(getUserAuth());
    },
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setAuth: (data) => {
      dispatch(setAuth(data));
    },
    fetchLocationData: () => {
      dispatch(fetchLocationData());
    },
    getLocationData: (lat, lng, address) => {
      dispatch(getLocationData(lat, lng, address));
    },
    setLoginPopup: (stage) => {
      dispatch(setLoginPopup(stage));
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    setCarPopup: (status) => {
      dispatch(setCarPopup(status));
    },
    setShowMobileIstand: (flag) => {
      dispatch(setShowMobileIstand(flag));
    },
    setCartProducts: (data) => {
      dispatch(setCartProducts(data));
    },
    setCartJobItems: (data) => {
      dispatch(setCartJobItems(data));
    },
    setCartAddons: (data) => {
      dispatch(setCartAddons(data));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    toggleSignupForm: (stage) => {
      dispatch(toggleSignupForm(stage));
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
    toggleChooseCar: (stage) => {
      dispatch(toggleChooseCar(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
