import React, { useEffect, useState } from "react";
import Style from "@assets/css/header.module.css";
import { handleClassNames } from "lib/cssHelpers";
import Link from "next/link";
import { IoIosArrowUp } from "react-icons/io";
import { toast } from "react-hot-toast";
import { animateScroll } from "react-scroll";
import API from "api";
import { Spinner } from "react-bootstrap";

const NewFooter = ({ hideScrollToTop = false, globalData }) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, Style);
  };

  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const header = { fontSize: 24, fontWeight: "700", color: "#0451be" };
  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const pattern = /^5\d{8}$/;
  const subscribeFun = () => {
    if (phone == "") {
      toast.dismiss();
      toast.error("Please Enter valid phone number!");
      return;
    }
    if (!pattern.test(phone)) {
      toast.dismiss();
      toast.error("Phone number must have 9 digits and start with 5");
      return;
    }
    setLoading(true);
    let bodyData = {
      SubmitBy: "Web",
      PhoneNumber: phone,
    };
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (phone != "") {
      API.post(`/v2/api/Subscribes`, bodyData, {
        headers: header,
      })
        .then(() => {
          toast.dismiss();
          toast.success("Subscription Successful!");
          setPhone("");
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  return (
    <footer
      style={{ zIndex: 0 }}
      className={`footer ${handleClasses("main-footer")}`}
    >
      <div className={handleClasses("ftlogo-bar")}>
        <img src="/assets/home/Ob_footer_logo.webp" alt="logo" />
      </div>
      <div className={handleClasses("footer-top")}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className={handleClasses("ft-fs-block")}>
                <h4 className={handleClasses("ft-header")}>Open Bonnet</h4>
                <p>
                  Open Bonnet is a Marketplace Where Car Owners Are Connected
                  With Verified Service Providers Through A Fully Digitized
                  Experience Offering Convenience, Quality &amp; Trust!
                </p>
                <ul className={handleClasses("style-ul contact-info-light")}>
                  <li>
                    <a href="tel:+9718002274357">
                      <i className="fa-solid fa-phone" />
                      <span style={{ marginLeft: "-3px" }}>
                        800-CARHELP (8002274357)
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://api.whatsapp.com/send?phone=9718002274357"
                      target="_blank"
                    >
                      <i className="fa-brands fa-whatsapp" />
                      <span>WhatsApp us</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:user@openbonnet.com">
                      <i className="fa-solid fa-envelope" />
                      <span>user@openbonnet.com</span>
                    </a>
                  </li>
                </ul>
                <div className={handleClasses("widget")}>
                  <ul className={handleClasses("style-ul social-icons-ft")}>
                    <li className={handleClasses("cs-facebook")}>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/openbonnetdotcom/"
                      >
                        <i className="fa-brands fa-square-facebook" />
                      </a>
                    </li>
                    <li className={handleClasses("cs-instagram")}>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/open_bonnet/"
                      >
                        <i className="fa-brands fa-square-instagram" />
                      </a>
                    </li>
                    <li className={handleClasses("cs-twitter")}>
                      <a
                        target="_blank"
                        href="https://www.twitter.com/open_bonnet"
                      >
                        <i className="fa-brands fa-square-x-twitter" />
                      </a>
                    </li>
                    <li className={handleClasses("cs-linkedin")}>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/openbonnet/"
                      >
                        <i className="fa-brands fa-linkedin" />
                      </a>
                    </li>
                    <li className={handleClasses("cs-youtube")}>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCMw6hZugnW7DxCBDg2ZmKdg/videos"
                      >
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                    <li className={handleClasses("cs-tiktok")}>
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/@open_bonnet"
                      >
                        <i className="fa-brands fa-tiktok" />
                      </a>
                    </li>
                    <li className={handleClasses("cs-snapchat")}>
                      <a
                        target="_blank"
                        href="https://www.snapchat.com/add/open_bonnet"
                      >
                        <i className="fa-brands fa-square-snapchat" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={handleClasses("widget")}>
                  <ul className={handleClasses("footer-payment style-ul")}>
                    <li className="pr-2">
                      <a href="javascript:void(0)">
                        <img
                          src="/assets/footer/visa.webp"
                          alt="visa"
                          style={{ borderRadius: "12px" }}
                          className="shadow"
                        />
                      </a>
                    </li>
                    <li className="pr-2">
                      <a href="javascript:void(0)">
                        <img
                          src="/assets/footer/master.webp"
                          alt="master_card"
                          style={{ borderRadius: "12px" }}
                          className="shadow"
                        />
                      </a>
                    </li>

                    <li className="pr-2">
                      <a href="javascript:void(0)">
                        <img
                          src="/assets/footer/image-256x143(google_pay).webp"
                          alt="google_pay"
                          style={{ borderRadius: "12px" }}
                          className="shadow"
                          // style={{ height: "40px", marginTop: "1px" }}
                        />
                      </a>
                    </li>

                    <li className="pr-2">
                      <a href="javascript:void(0)">
                        <img
                          src="/assets/footer/image-256x143.webp"
                          alt="apple_pay"
                          // className="border rounded"
                          style={{ borderRadius: "12px" }}
                          className="shadow"
                          // style={{ height: "40px", marginTop: "1px" }}
                        />
                      </a>
                    </li>
                    {/* <li className="pr-2">
                      <a href="javascript:void(0)">
                        <img src="/assets/footer/tabby.webp" alt="tabby" />
                      </a>
                    </li> */}
                    {/* <li className="pr-2">
                      <a href="javascript:void(0)">
                        <img src="/assets/footer/mamo-pay.webp" alt="tabby" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="">
                <h4 className={handleClasses("ft-header")}>Links</h4>
                <ul
                  className={handleClasses(
                    "widget-links footer-links-bl style-ul"
                  )}
                >
                  <li>
                    <Link href="/about-us">
                      <a className={handleClasses("foot-text")}>About Us</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://blog.openbonnet.com/blog">
                      <a className="foot-text">Our Blogs</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/faq">
                      <a className={handleClasses("foot-text")}>FAQs</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={"/workshop-network"}>
                      <a className={handleClasses("foot-text")}>
                        Workshop Network
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/workshop-marketing">
                      <a className={handleClasses("foot-text")}>
                        For Service Providers
                      </a>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/">
                      <a className={handleClasses("foot-text")}>
                        Service Date Calculator
                      </a>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="/blog/grid-standard-right-sidebar">
                      <a className={handleClasses("foot-text")}>Blogs</a>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="/">
                      <a className={handleClasses("foot-text")}>
                        Tools & Calculators
                      </a>
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/privacy-policy">
                      <a className={handleClasses("foot-text")}>
                        Privacy Policy
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions">
                      <a className={handleClasses("foot-text")}>
                        Service Terms
                      </a>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/fleet-service">
                      <a className={handleClasses("foot-text")}>
                        Fleet Servicing Solution
                      </a>
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/super-car">
                      <a className={handleClasses("foot-text")}>
                        Supercar Services
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/sign-up">
                      <a className={handleClasses("foot-text")}>User Sign Up</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="">
                <h4 className={handleClasses("ft-header")}>Services</h4>
                <ul
                  className={handleClasses(
                    "widget-links style-ul footer-links-bl"
                  )}
                >
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/oil-change`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Oil Change Periodic Services
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/repair`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Maintenance & Repair
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/car-care`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Car Care & Cleaning
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/tires-wheels`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Tyres & Wheels
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/battery`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Battery Assistance
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/inspection`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Pre-Purchase Inspection
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/ac-service`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Air Conditioning Services
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/mobile-mechanic`}
                    >
                      <a className={handleClasses("foot-text")}>
                        At-Home Services
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/breakdown`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Car Breakdown
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/car-wash-at-home`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Car Wash at Home
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/service-contract`}
                    >
                      <a className={handleClasses("foot-text")}>
                        Service Contract
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={handleClasses("whatsapp-container")}>
              <div className="row">
                <div className="col-md-7">
                  <div className={handleClasses("whatsapp-left")}>
                    <div className={handleClasses("whatsapp-icon")}>
                      <img src="/assets/whatsapp_new.svg" alt="whatsapp" />
                    </div>
                    <div className={handleClasses("whatsapp-txt")}>
                      <h5 className="text-white">
                        Subscribe to our WhatsApp Channel
                      </h5>
                      <span>
                        Stay connected with OB &amp; get alerts of best offers
                        for your car.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className={handleClasses("whatsapp-right")}>
                    <div className={handleClasses("mob-num")}>
                      <img
                        className={handleClasses("img-fluid")}
                        src="/assets/header/uae-flag.svg"
                        alt="uae-flag"
                      />
                      <span>+971</span>
                      <input
                        type="text"
                        className={handleClasses("form-control")}
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        maxLength={9}
                      />
                    </div>
                    <div className={handleClasses("wp-sb")}>
                      <button
                        onClick={subscribeFun}
                        className={`btn d-flex justify-content-center ${handleClasses(
                          "cm-orange-btn"
                        )}`}
                      >
                        Subscribe
                        {loading ? (
                          <span>
                            <Spinner
                              animation="grow"
                              role="status"
                              className="ml-2"
                              size="sm"
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          </span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={handleClasses("imp-footer")}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p
                className={`mb-3 mb-md-0 text-left  ${handleClasses("greyC")}`}
              >
                Using this website you agree to our{" "}
                <a href="/terms-and-conditions">Terms &amp; Conditions</a>{" "}
                {/* */}
                and{/* */} <a href="/privacy-policy">Privacy Policy</a>.
              </p>
            </div>
            <div className="col-md-6">
              <p
                className={`mb-3 mb-md-0 text-right ${handleClasses("greyC")}`}
              >
                © 2023 - 2024 All rights reserved by{/* */}{" "}
                <a
                  href="https://www.openbonnet.com"
                  className={handleClasses("blueC")}
                  target="_blank"
                >
                  Open Bonnet
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {!hideScrollToTop && (
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <IoIosArrowUp />
        </button>
      )}
    </footer>
  );
};

export default NewFooter;
