import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import {
  fetchCars,
  fetchNotification,
} from "../../redux/actions/profileAction";
import Link from "next/link";
import OutsideClickHandler from "react-outside-click-handler";
import { FaDotCircle } from "react-icons/fa";
import Scrollbars from "react-custom-scrollbars";
import { toggleNotification } from "redux/actions/globalAction";
import { useRouter } from "next/router";

function Notification({
  isMobile,
  show,
  profile,
  fetchNotification,
  toggleNotification,
}) {
  const router = useRouter();
  useEffect(() => {
    fetchNotification();
  }, []);

  const handleClose = () => {
    toggleNotification(false);
  };

  return (
    <>
      {/* <div
        className="bg-layer"
        onClick={() => {
          setShow(false);
        }}
      ></div> */}
      <OutsideClickHandler onOutsideClick={() => toggleNotification(false)}>
        <divc
          className={
            router.pathname != "/checkout"
              ? "account-box notification-custom-hight pt-3 pb-1"
              : "account-box notification-custom-hight pt-3 pb-1 custom-height-checkout"
          }
          style={{
            width: isMobile ? "100%" : "35%",
            marginTop: isMobile ? "50px" : "28px",
            zIndex: isMobile ? 91 : 11,
          }}
        >
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <h5>Notifications</h5>
              <h6 style={{ cursor: "pointer" }} onClick={() => handleClose()}>
                X
              </h6>
            </div>
            <hr />
            {profile.notifications?.Data?.lenght > 0 ? (
              <Row>
                <Scrollbars style={{ height: 400 }}>
                  {profile.notifications?.Data?.map((notification) => (
                    <Col
                      xs={12}
                      className={`d-flex align-items-center justify-content-between px-0 py-1 border-bottom ${
                        notification.IsRead ? "bg-grey" : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/images/category/OIL%20CHANGE.svg"
                          width={40}
                          className="mx-2"
                          alt="OIL-CHANGE"
                        />
                        <div>
                          <h5 className="m-0">{notification.Message}</h5>
                          <p>2 Hrs</p>
                        </div>
                      </div>
                      {/* <FaDotCircle className="mr-2 text-primary" /> */}
                    </Col>
                  ))}
                  {/* <Col
                  xs={12}
                  className=" align-items-center justify-content-between px-0 py-1 border-bottom bg-grey"
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/category/OIL%20CHANGE.svg"
                      width={40}
                      className="mx-2"
                    />
                    <div>
                      <h5>Title</h5>
                      <p>description</p>
                    </div>
                  </div>
                  <FaDotCircle className="mr-2 text-primary" />
                </Col> */}
                </Scrollbars>
              </Row>
            ) : (
              <div style={{ height: 400 }}>
                <Alert variant="warning">
                  No Notification Recived Yet!
                  <p className="mt-2" style={{ fontSize: 14 }}>
                    <em>
                      New marketing & system generated notification will show
                      here
                    </em>
                  </p>
                </Alert>
              </div>
            )}
          </Container>
        </divc>
      </OutsideClickHandler>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getBrand: () => {
      dispatch(getBrand());
    },
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId
    ) => {
      dispatch(
        getCarBrandModal(brand, modal, logoBrand, logoModal, brandId, modelId)
      );
    },
    fetchNotification: () => {
      dispatch(fetchNotification());
    },
    toggleNotification: (stage) => {
      dispatch(toggleNotification(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
