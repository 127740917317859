import { handleClassNames } from "lib/cssHelpers";
import React, { useState } from "react";
import styles from "@assets/css/header.module.css";
import { clearLoginData, resetStore } from "redux/actions/loginAction";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { gtmCall } from "lib/google-tag-manager";
import { toggleNotification } from "redux/actions/globalAction";
import {
  fetchActiveOrders,
  fetchForActiveOrders,
  getProfileData,
  toggleUpdateProfile,
} from "redux/actions/profileAction";
import { useEffect } from "react";
import { setjobHistoryScreen } from "redux/actions/globalAction";
import { isMobile } from "react-device-detect";
import { packageSize } from "lib/config";
const SideBar = ({
  showSideBar,
  handleSidebar,
  clearLoginData,
  resetStore,
  loginData,
  fetchActiveOrders,
  fetchForActiveOrders,
  ob,
  toggleNotification,
  toggleUpdateProfile,
  profile,
  getProfileData,
  setjobHistoryScreen,
}) => {
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };
  const initialAnimation = {
    order: false,
    quotation: false,
  };
  const [blinkingAnimation, setBlinkingAnimation] = useState(initialAnimation);

  const router = useRouter();

  const profileImageUrl =
    profile?.info?.ImageUrl ||
    loginData?.userAuth?.Avatar ||
    "/assets/header/user-dummy.webp";

  useEffect(() => {
    getProfileData();
    toggleUpdateProfile(false);
  }, [loginData?.userAuth, profile.IsProfileUpdate]);
  useEffect(async () => {
    await fetchForActiveOrders();
  }, []);
  useEffect(() => {
    const animation = { ...initialAnimation };
    const orders = profile.forActiveOrders;
    // const quotations = profile.activeQuotations;

    // console.log(profile.forActiveOrders)
    if (orders && orders?.Data && orders?.Data?.CustomerPurchaseOrder?.Id) {
      animation.order = true;
    }

    if (orders && orders?.Data && orders?.Data?.CustomerQuotationRequest?.Id) {
      animation.quotation = true;
    }

    setBlinkingAnimation(animation);
  }, [profile.forActiveOrders]);
  const handleRedirection = (event, url, show = false) => {
    event.stopPropagation();
    if (show) {
      console.log("inside");
      setjobHistoryScreen(true);
    } else {
      setjobHistoryScreen(false);
    }
    if (router.pathname == url) {
      handleSidebar();
      return;
    }
    router.push(url);
  };

  const handleLogout = async () => {
    await clearLoginData();
    await resetStore();
    await router.push("/");
    handleSidebar();
  };

  const handleNotification = () => {
    toggleNotification(true);
    handleSidebar();
  };

  return (
    <>
      <div
        onClick={() => handleSidebar()}
        className={handleClasses(showSideBar ? "backdrop show" : "backdrop")}
      ></div>
      <aside
        className={handleClasses(
          showSideBar ? "nav__menu--right open" : "nav__menu--right"
        )}
      >
        <span
          onClick={() => handleSidebar()}
          className={handleClasses("close-nav")}
        >
          <i className="fa-solid fa-circle-chevron-right" />
        </span>
        <div className={handleClasses("sidebar-menu")}>
          <div className={handleClasses("sd-user-info")}>
            <div className={handleClasses("sd-user-info-user-image")}>
              <img src={profileImageUrl} alt="profile" />
            </div>
            <div className={handleClasses("user-txt-info")}>
              <h3>
                {profile.info
                  ? profile?.info?.FirstName + "" + profile?.info?.LastName
                  : loginData?.userAuth?.FirstName +
                      " " +
                      loginData?.userAuth?.LastName || "fetch.."}
              </h3>
              <span>
                +971{" "}
                {profile.info
                  ? profile?.info?.PhoneNumber
                  : loginData?.userAuth?.PhoneNumber}
              </span>
              <span>
                {profile.info
                  ? profile?.info?.Email
                  : loginData?.userAuth?.Email}
              </span>
            </div>
          </div>
          <div
            className={handleClasses("sd-user-info  nonlogged-sidebar")}
            style={{ display: "none" }}
          >
            <div
              className={handleClasses("sd-user-info-user-image dummyimage")}
            >
              <img src="/assets/header/user-dummy.webp" alt="dummy-user" />
            </div>
            <div className={handleClasses("social-control-bar")}>
              <div className={handleClasses("follow-txt")}>
                <i />
                <span>
                  <strong>Follow us on</strong>
                </span>
              </div>
              <ul className={handleClasses("style-ul")}>
                <li className={handleClasses("cs-facebook")}>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/openbonnetdotcom/"
                  >
                    <i className="fa-brands fa-square-facebook" />
                  </a>
                </li>
                <li className={handleClasses("cs-instagram")}>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/open_bonnet/"
                  >
                    <i className="fa-brands fa-square-instagram" />
                  </a>
                </li>
                <li>
                  {" "}
                  className={handleClasses("cs-twitter")}
                  <a target="_blank" href="https://www.twitter.com/open_bonnet">
                    <i className="fa-brands fa-square-x-twitter" />
                  </a>
                </li>
                <li className={handleClasses("cs-linkedin")}>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/openbonnet/"
                  >
                    <i className="fa-brands fa-linkedin" />
                  </a>
                </li>
                <li className={handleClasses("cs-youtube")}>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCMw6hZugnW7DxCBDg2ZmKdg/videos"
                  >
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
                <li
                  className={handleClasses("cs-snapchat cs-snapchat-sidebar")}
                >
                  <a
                    target="_blank"
                    href="https://www.snapchat.com/add/open_bonnet"
                  >
                    <i className="fa-brands fa-square-snapchat" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={handleClasses("lf-mn-services")}>
            <ul className={handleClasses("style-ul")}>
              <li onClick={(e) => handleRedirection(e, "/profile/orders")}>
                <a>
                  <img src="/assets/header/sidebar/order.webp" alt="order" />
                  {blinkingAnimation.order && (
                    <div
                      className="progress-waves"
                      style={{ right: "-20px", top: "-34px" }}
                    >
                      <span />
                    </div>
                  )}
                  <span>My Orders</span>
                </a>
              </li>
              <li onClick={(e) => handleRedirection(e, "/profile/quotation")}>
                <a>
                  <img src="/assets/header/sidebar/Notes.webp" alt="Notes" />
                  {blinkingAnimation.quotation && (
                    <div
                      className="progress-waves"
                      style={{ right: "-20px", top: "-34px" }}
                    >
                      <span />
                    </div>
                  )}
                  <span>My Quotes</span>
                </a>
              </li>
              <li
                onClick={(e) => handleRedirection(e, "/profile/orders", true)}
              >
                <a>
                  <img
                    src="/assets/header/sidebar/history.webp"
                    alt="history"
                  />
                  <span>OB History</span>
                </a>
              </li>
            </ul>
          </div>
          <div className={handleClasses("coins-sd-bar")}>
            <div className={handleClasses("coins-sd-bar-img")}>
              <img
                src="/assets/header/ob-coin.webp"
                alt="coin"
                style={{ height: "50px" }}
              />
            </div>
            <div
              onClick={(e) => handleRedirection(e, "/profile/ob-points")}
              className={handleClasses("coins-sd-bar-txt")}
            >
              <h5>OB CashPoints</h5>
              <strong>{parseFloat(ob).toFixed(0) || 0}</strong>
            </div>
          </div>
          <div className={handleClasses("lf-main-menu")}>
            <ul className={handleClasses("style-ul")}>
              <li onClick={(e) => handleRedirection(e, "/profile/dashboard")}>
                <a href="javascript:void(0)">
                  <label>
                    <img
                      src="/assets/header/sidebar/dashboard.svg"
                      alt="dashboard"
                    />
                  </label>
                  <span>My Dashboard</span>
                </a>
              </li>
              <li onClick={(e) => handleRedirection(e, "/profile")}>
                <a href="javascript:void(0)">
                  <label>
                    <img src="/assets/header/sidebar/user.svg" alt="user" />
                  </label>
                  <span>Profile</span>
                </a>
              </li>
              <li
                onClick={(e) => handleRedirection(e, "/profile/my-address")}
                className={handleClasses("address")}
              >
                <a href="javascript:void(0)">
                  <label>
                    <img src="/assets/header/sidebar/pin.svg" alt="pin" />
                  </label>
                  <span>Manage Address </span>
                </a>
              </li>
              {isMobile && (
                <li onClick={(e) => handleRedirection(e, "/profile/my-car")}>
                  <a href="javascript:void(0)">
                    <label>
                      <img src="/assets/header/sidebar/car.svg" alt="car" />
                    </label>
                    <span> My Cars </span>
                  </a>
                </li>
              )}
              <li
                onClick={() => handleNotification()}
                className={handleClasses("bell")}
              >
                <a href="javascript:void(0)">
                  <label>
                    <img src="/assets/header/sidebar/bell1.webp" alt="bell" />
                  </label>
                  <span>Notifications</span>
                </a>
              </li>
              <li
                onClick={() => handleLogout()}
                className={handleClasses("logout")}
              >
                <a href="javascript:void(0)">
                  <label>
                    <img src="/assets/header/sidebar/logout.svg" alt="logout" />
                  </label>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={handleClasses("sidebar-bottom")}>
          <div className={handleClasses("sidebar-bottom-content")}>
            <a onClick={() => gtmCall()} href="tel:8002274357" target="_blank">
              <img src="/assets/header/sidebar/calling.svg" alt="call" />{" "}
              <span>8002274357</span>
            </a>
          </div>
          <div className={handleClasses("social-control-bar")}>
            <div className={handleClasses("follow-txt")}>
              <i />
              <span>
                <strong>Follow us on</strong>
              </span>
            </div>
            <ul className={handleClasses("style-ul")}>
              <li className={handleClasses("cs-facebook")}>
                <a
                  target="_blank"
                  href="https://www.facebook.com/openbonnetdotcom/"
                >
                  <i className="fa-brands fa-square-facebook" />
                </a>
              </li>
              <li className={handleClasses("cs-instagram")}>
                <a
                  target="_blank"
                  href="https://www.instagram.com/open_bonnet/"
                >
                  <i className="fa-brands fa-square-instagram" />
                </a>
              </li>
              <li className={handleClasses("cs-twitter")}>
                <a target="_blank" href="https://www.twitter.com/open_bonnet">
                  <i className="fa-brands fa-square-x-twitter" />
                </a>
              </li>
              <li className={handleClasses("cs-linkedin")}>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/openbonnet/"
                >
                  <i className="fa-brands fa-linkedin" />
                </a>
              </li>
              <li className={handleClasses("cs-youtube")}>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCMw6hZugnW7DxCBDg2ZmKdg/videos"
                >
                  <i className="fa-brands fa-youtube" />
                </a>
              </li>
              <li className={handleClasses("cs-snapchat cs-snapchat-sidebar")}>
                <a
                  target="_blank"
                  href="https://www.snapchat.com/add/open_bonnet"
                >
                  <i className="fa-brands fa-square-snapchat" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    resetStore: () => {
      dispatch(resetStore());
    },
    toggleNotification: (stage) => {
      dispatch(toggleNotification(stage));
    },
    toggleUpdateProfile: (data) => {
      dispatch(toggleUpdateProfile(data));
    },
    getProfileData: () => {
      dispatch(getProfileData());
    },
    setjobHistoryScreen: (flag) => {
      dispatch(setjobHistoryScreen(flag));
    },
    fetchActiveOrders: (carId, size, page) => {
      dispatch(fetchActiveOrders(carId, size, page));
    },
    fetchForActiveOrders: () => {
      dispatch(fetchForActiveOrders());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
