import axios from "axios";
import toast from "react-hot-toast";
import { saveCart } from "../../lib/cart";
import {
  SET_CART_ADDONS,
  SET_CART_MEMBERSHIP,
  SET_CART_PRODUCTS,
  SET_CART_STAGE,
  SET_CART_WORKSHOP,
  SET_CART_JOB_ITEMS,
  FETCH_CART_ADDONS,
  SET_CART_DETAILS,
  SET_CART_PROMOTIONS,
  SET_CART_APPLIED_OFFER,
  SET_CART_PICKUP_DROP_PRICE,
  SET_CART_APPLIED_PICKUP_DROP_PRICE,
  SET_CART_PACKAGES,
  SET_CART_SELECTED_PICKUP_DROP,
  SET_CART_JOURNEY,
  SET_CART_TYRE_PARAMETER,
  SET_CART_BATTERY_PARAMETER,
  SET_OB_POINTS,
  APPLY_OB_POINTS,
  SET_ORDER_SUCCESS,
  SET_SELECTED_QUOTATION_FORM,
  SET_SHOW_MOBILE_ISLAND,
  RESET_WORKSHOP,
  RESET_CART,
  TOGGLE_MY_ACCOUNT,
  SET_OB_EARN_POINTS,
  SET_OB_BURN_POINTS,
  SET_APPLIED_PROMOTIONS,
  REMOVE_APPLIED_PROMOTIONS,
  ADDDED_PICKUP_DROP_PRICE,
  SHOW_LOADER,
  UPDATE_CART_JOB_ITEMS,
  UPDATE_CART_PRODUCTS,
  SET_APPLIED_CART_OFFERS,
  SET_SELECTED_AREA,
} from "./types";

export const setCartStage = (stage) => async (dispatch) => {
  dispatch({
    type: SET_CART_STAGE,
    payload: stage,
  });
};

export const setCartJourney = (stage) => async (dispatch) => {
  dispatch({
    type: SET_CART_JOURNEY,
    payload: stage,
  });
};
export const setSelectedArea = (area) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_AREA,
    payload: area,
  });
}

export const setCartTypeParameter = (data) => async (dispatch) => {
  dispatch({
    type: SET_CART_TYRE_PARAMETER,
    payload: data,
  });
};

export const setCartBatteryParameter = (data) => async (dispatch) => {
  dispatch({
    type: SET_CART_BATTERY_PARAMETER,
    payload: data,
  });
};

export const setCartWorkshop = (data) => async (dispatch) => {
  localStorage.setItem("workshop-selected", JSON.stringify(data));
  localStorage.removeItem("startOver");
  dispatch({
    type: SET_CART_WORKSHOP,
    payload: data,
  });
};

export const fetchCartWorkshop = () => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("workshop-selected"));
  dispatch({
    type: SET_CART_WORKSHOP,
    payload: data,
  });
};

export const setCartProducts = (products) => async (dispatch) => {
  localStorage.setItem("cart-product", JSON.stringify(products));
  localStorage.removeItem("startOver");
  dispatch({
    type: SET_CART_PRODUCTS,
    payload: products,
  });
};

export const fetchCartProducts = () => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("cart-product"));
  dispatch({
    type: SET_CART_PRODUCTS,
    payload: data ? data : [],
  });
};

export const setCartAddons = (addons) => async (dispatch) => {
  localStorage.setItem("cart-addon", JSON.stringify(addons));
  dispatch({
    type: SET_CART_ADDONS,
    payload: addons,
  });
};

export const setCartMembership = (membership) => async (dispatch) => {
  // localStorage.setItem("cart-addon", JSON.stringify(membership))
  dispatch({
    type: SET_CART_MEMBERSHIP,
    payload: membership,
  });
};

export const setCartJobItems = (jobItems) => async (dispatch) => {
  const FilterJObItems = jobItems.filter((item) => item);
  localStorage.setItem("cart-job-items", JSON.stringify(jobItems));
  dispatch({
    type: SET_CART_JOB_ITEMS,
    payload: FilterJObItems,
  });
};

export const updateCartItems = (jobItems, products) => async (dispatch) => {
  localStorage.setItem("cart-job-items", JSON.stringify(jobItems));
  localStorage.setItem("cart-product", JSON.stringify(products));
  dispatch({
    type: UPDATE_CART_JOB_ITEMS,
    payload: jobItems,
  });
  dispatch({
    type: UPDATE_CART_PRODUCTS,
    payload: products,
  });
};

export const showLoader = (flag) => async (dispatch) => {
  dispatch({
    type: SHOW_LOADER,
    payload: flag,
  });
};

export const fetchCartJobItems = () => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("cart-job-items"));
  dispatch({
    type: SET_CART_JOB_ITEMS,
    payload: data ? data : [],
  });
};

export const fetchCartAddons =
  (workshopId, variantId, carModeId) => async (dispatch) => {
    // let data = JSON.parse(localStorage.getItem("cart-addon"));
    // try {
    //   const { data } = await axios.post(
    //     `${process.env.NEXT_PUBLIC_API_URL}v2/api/products/addons`,
    //     {
    //       CarModelId: carModeId,
    //       JourneyVariants: [
    //         {
    //           JourneyVariantId: 46,
    //           JourneyVariantItemId: 1021,
    //           WorkshopId: 1001,
    //           JobId: 2,
    //           JobItemId: 1,
    //         },
    //       ],
    //     },
    //     {
    //       headers: {
    //         // Authorization: "bearer " + token.access_token
    //       },
    //     }
    //   );
    //   dispatch({
    //     type: FETCH_CART_ADDONS,
    //     payload: data.Data,
    //   });
    // } catch (error) {
    //   console.log("error ", error);
    // }
  };

export const fetchAllCartAddons = (jobs, carModeId) => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("cart-addon"));
  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/products/addons`,
      {
        CarModelId: carModeId,
        JourneyVariants: jobs,
      },
      {
        headers: {
          // Authorization: "bearer " + token.access_token
        },
      }
    );
    dispatch({
      type: FETCH_CART_ADDONS,
      payload: data.Data,
    });
  } catch (error) {
    console.log("error ", error);
  }
};

export const addToCart =
  (workshopId, variant, carModeId) => async (dispatch) => {
    // let data = JSON.parse(localStorage.getItem("cart-addon"));
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/workshop/${workshopId}/jobs?page=1&variantId=${variant.Id}&carModelId=${carModeId}&sortingCol=Distance&workshopId=${workshopId}&lat=1&lng=1&size=40`,
        {
          headers: {
            // Authorization: "bearer " + token.access_token
          },
        }
      );
      dispatch({
        type: SET_CART_JOB_ITEMS,
        payload: data.Data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Failed");
      console.log("error ", error);
    }
  };

export const setCartAddedAddons = (addons) => async (dispatch) => {
  localStorage.setItem("cart-addons", JSON.stringify(addons));
  dispatch({
    type: SET_CART_ADDONS,
    payload: addons,
  });
};

export const fetchCartAddedAddons = () => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("cart-addons"));
  dispatch({
    type: SET_CART_ADDONS,
    payload: data ? data : [],
  });
};

export const setCartDetails = (details) => async (dispatch) => {
  localStorage.setItem("cart-details", JSON.stringify(details));
  dispatch({
    type: SET_CART_DETAILS,
    payload: details,
  });
};

export const fetchCartPromotions = (parameters) => async (dispatch) => {
  // let data = JSON.parse(localStorage.getItem("cart-addon"));
  let token = await JSON.parse(localStorage.getItem("login_access"));
  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/promotions/cart`,
      parameters,
      {
        headers: token
          ? {
              Authorization: "bearer " + token.access_token,
            }
          : {},
      }
    );

    dispatch({
      type: SET_CART_PROMOTIONS,
      payload: data.Data,
    });
  } catch (error) {
    console.log("errorrr ", error);
  }
};

export const applyOffer = (offer) => async (dispatch) => {
  // localStorage.setItem("cart-offer", JSON.stringify(offer))
  dispatch({
    type: SET_CART_APPLIED_OFFER,
    payload: offer,
  });
};

export const AppliedCartOffers = (offer) => async (dispatch) => {
  await sessionStorage.setItem("cart-offers", JSON.stringify(offer));
  dispatch({
    type: SET_APPLIED_CART_OFFERS,
    payload: offer,
  });
};

export const fetchPickupDropPrice =
  (parameters, workshopId) => async (dispatch) => {
    // let data = JSON.parse(localStorage.getItem("cart-addon"));
    // v2/api/workshops/${workshopId}/pick-up-drop
    parameters.workshopId = workshopId;
    let token = await JSON.parse(localStorage.getItem("login_access"));
    try {
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/pick-up-drop`,
        parameters,
        {
          headers: token
            ? {
                Authorization: "bearer " + token.access_token,
              }
            : {},
        }
      );

      // data.Data = {
      //   IsEligibaleForFreePickUpDrop: false,
      //   Price: null,
      //   Prices: {
      //     PickUpDroPrice: 100.0,
      //     PickUpPrice: 75.0,
      //     DropPrice: 25.0,
      //   },
      // };

      dispatch({
        type: SET_CART_PICKUP_DROP_PRICE,
        payload: data.Data,
      });
    } catch (error) {
      console.log("errorrr ", error);
    }
  };

export const applyPickupDrop = (rate) => async (dispatch) => {
  // localStorage.setItem("cart-offer", JSON.stringify(offer))
  localStorage.setItem("shipping", rate);
  dispatch({
    type: SET_CART_APPLIED_PICKUP_DROP_PRICE,
    payload: rate,
  });
};

export const addedPickupDrop = (rate) => async (dispatch) => {
  dispatch({
    type: ADDDED_PICKUP_DROP_PRICE,
    payload: rate,
  });
};

export const setCartAddedPackages = (packages) => async (dispatch) => {
  localStorage.setItem("cart-packages", JSON.stringify(packages));
  dispatch({
    type: SET_CART_PACKAGES,
    payload: packages,
  });
};

export const fetchCartAddedPackages = () => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem("cart-packages"));
  dispatch({
    type: SET_CART_PACKAGES,
    payload: data ? data : [],
  });
};

export const getOBPoints =
  (OriginalCost, Cost, PromotionId, MembershipId) => async (dispatch) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    try {
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/checkout/ob-points`,
        {
          OriginalCost,
          Cost,
          PromotionId,
          MembershipId,
        },
        {
          headers: token
            ? {
                Authorization: "bearer " + token.access_token,
              }
            : {},
        }
      );
      dispatch({
        type: SET_OB_POINTS,
        payload: data.Data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
      console.log("error ", error);
    }
  };

export const getOBEarnPoints =
  (Jobs, AmountAfterDiscounts) => async (dispatch) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    try {
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/ob-points/checkout/earn-amount`,
        {
          Jobs,
          AmountAfterDiscounts,
        },
        {
          headers: token
            ? {
                Authorization: "bearer " + token.access_token,
              }
            : {},
        }
      );
      dispatch({
        type: SET_OB_EARN_POINTS,
        payload: data.Data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
      console.log("error ", error);
    }
  };

export const getOBBurnPoints =
  (Jobs, AmountAfterDiscounts) => async (dispatch) => {
    let token = await JSON.parse(localStorage.getItem("login_access"));
    try {
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}v2/api/ob-points/checkout/burn-amount`,
        {
          Jobs,
          AmountAfterDiscounts,
        },
        {
          headers: token
            ? {
                Authorization: "bearer " + token.access_token,
              }
            : {},
        }
      );
      dispatch({
        type: SET_OB_BURN_POINTS,
        payload: data.Data,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
      console.log("error ", error);
    }
  };

export const applyRedeem = (points) => async (dispatch) => {
  dispatch({
    type: APPLY_OB_POINTS,
    payload: points,
  });
};

export const setOrderSuccess = (data) => async (dispatch) => {
  dispatch({
    type: SET_ORDER_SUCCESS,
    payload: data,
  });
};

export const setSelectedQuotationForm = (item) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_QUOTATION_FORM,
    payload: item,
  });
};

export const setShowMobileIstand = (flag) => async (dispatch) => {
  dispatch({
    type: SET_SHOW_MOBILE_ISLAND,
    payload: flag,
  });
};

export const resetWorkshop = () => async (dispatch) => {
  dispatch({
    type: RESET_WORKSHOP,
  });
};

export const resetCart = () => async (dispatch) => {
  localStorage.removeItem("cart-packages");
  localStorage.removeItem("cart-job-items");
  localStorage.removeItem("cart-addons");
  dispatch({
    type: RESET_CART,
  });
};

export const showMyAccount = (data) => async (dispatch) => {
  dispatch({
    type: TOGGLE_MY_ACCOUNT,
    payload: data,
  });
};

export const getPromotionsPromocode = async (request) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/promotions/cart`,
      {
        ...request,
      },
      {
        headers: token
          ? {
              Authorization: "bearer " + token.access_token,
            }
          : {},
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const removePromotionsPromocode = () => async (dispatch) => {
  dispatch({
    type: REMOVE_APPLIED_PROMOTIONS,
  });
  const triggerAction = localStorage.getItem("trigger-action");
  if (triggerAction) {
    localStorage.removeItem("trigger-action");
    return;
  }
  dispatch(AppliedCartOffers(null));
  localStorage.removeItem("trigger-action");
  toast.dismiss();
  toast.success("Removed Offer");
};

export const applyPromotionsPromocode = (request) => async (dispatch) => {
  let token = await JSON.parse(localStorage.getItem("login_access"));
  try {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}v2/api/promotions/apply`,
      {
        ...request,
      },
      {
        headers: token
          ? {
              Authorization: "bearer " + token.access_token,
            }
          : {},
      }
    );
    
    if (data.Data && data.Data?.promotions == null) {
      toast.dismiss();
      toast.error("Invalid Promocode");
      return;
    }
    dispatch({
      type: SET_APPLIED_PROMOTIONS,
      payload: data.Data,
    });
    toast.dismiss();
    toast.success("Offer Applied");
  } catch (error) {
    
    toast.dismiss();
    toast.error("Invalid Promocode");
    console.log(error);
  }
};
