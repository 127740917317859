import { Fragment } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Head from "next/head";
import { useState } from "react";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { ThreeDots } from "react-loader-spinner";
import { connect } from "react-redux";
import Schemas from "./schema.json";
import NewHeader from "components/newHeader";
import NewFooter from "components/Footer/NewFooter";
import {
  setLoader,
  setOtpScreen,
  toggleChooseCar,
  toggleLocation,
  toggleLoginForm,
  toggleManageCar,
  toggleNotification,
  toggleSearch,
  toggleSignupForm,
  toggleSosForm,
} from "redux/actions/globalAction";
import { isMobile } from "react-device-detect";
import ExitIntentPopup from "components/Home/Exit-intent-popup";
import useExitIntentWithConfig from "lib/useExitIntentWithConfig";
const footerDisabledURLs = ["/services/[...slug]"];

const Layout = ({
  children,
  navPositionClass,
  title,
  workshop_detail,
  mData,
  locationOn,
  carData,
  hideScrollToTop = false,
  globalData,
  askLocation,
  hideFooter = false,
  hideHeader = false,
  toggleLocation,
  toggleLoginForm,
  toggleSosForm,
  toggleChooseCar,
  toggleSignupForm,
  toggleManageCar,
  toggleSearch,
  toggleNotification,
  setLoader,
  setOtpScreen,
}) => {
  const router = useRouter();
  const [schema, setSchema] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [metaData, setMetaData] = useState({
    title: "",
    description: "",
    keywords: "",
  });
  const { registerHandler, unsubscribe } = useExitIntentWithConfig();
  const hasPopupBeenDisplayed = () =>
    document.cookie.indexOf("use-exit-intent=true") !== -1;

  const isThankyouPage = () =>
    router.pathname === "/checkout/thank-you" ||
    router.pathname === "/request/thank-you" ||
    router.pathname === "/request" ||
    router.pathname === "/survey/user-feedback" ||
    router.pathname === "/payment/[id]" ||
    router.pathname === "/sign-up";
  registerHandler({
    id: "modal-handler",
    handler: () => {
      if (!hasPopupBeenDisplayed() && !isThankyouPage()) {
        setShowPopup(true);
        document.cookie = "use-exit-intent=true; expires=30";
      }
    },
  });

  const [popupDisplayed, setPopupDisplayed] = useState(false);
  const handleClose = () => {
    setShowPopup(false);
    sessionStorage.setItem("visitedExitIntent", true);
  };

  const site = "https://openbonnet.com";
  const canonicalURL = site + router.asPath;

  useEffect(() => {
    const handleUndefined = (target, value) => {
      return target == "undefined" ? value : target;
    };
    const CurrentPage =
      router?.query && router?.query?.slug?.length
        ? handleUndefined(router?.query?.slug[1], "oil-change")
        : router.pathname;
    const Data = Schemas;
    Data.schemas?.map((item) => {
      if (item.page == CurrentPage) {
        setSchema(item.schema);
      }
    });
    Data.metaTags?.map((item) => {
      if (item.page == CurrentPage) {
        const { page, ...rest } = item;
        if (router?.query && router?.query?.slug?.length) {
          rest.title = `${router?.query?.slug[0]}-${handleUndefined(
            router?.query?.slug[1],
            "oil-change"
          )}-${handleUndefined(router?.query?.slug[2], "car")}`;
        }
        setMetaData(rest);
      }
    });
  }, [router]);

  const handleBackDrop = () => {
    toggleLocation(false);
    toggleLoginForm(false);
    toggleSosForm(false);
    toggleChooseCar(false);
    toggleSignupForm(false);
    toggleManageCar(false);
    toggleSearch(false);
    toggleNotification(false);
    setOtpScreen(false);
  };

  useEffect(() => {
    const duration = router.pathname === "/services/[...slug]" ? 150000 : 15000;
    if (isMobile) {
      if (
        !globalData.LoginForm &&
        !globalData.SignupForm &&
        !globalData.otpScreen
      ) {
        if (!hasPopupBeenDisplayed() && !isThankyouPage()) {
          let inactivityTimeout;
          const resetInactivityTimeout = () => {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(() => {
              if (!hasPopupBeenDisplayed()) {
                setShowPopup(true);
                document.cookie = "use-exit-intent=true; expires=30";
              }
            }, duration);
          };

          const handleActivity = () => {
            resetInactivityTimeout();
          };

          document.addEventListener("keydown", handleActivity);
          document.addEventListener("mousedown", handleActivity);
          resetInactivityTimeout();

          return () => {
            document.removeEventListener("keydown", handleActivity);
            document.removeEventListener("mousedown", handleActivity);
            clearTimeout(inactivityTimeout);
          };
        }
      }
    }
    if (!hasPopupBeenDisplayed() && !isThankyouPage() && !isMobile) {
      let inactivityTimeout;
      const resetInactivityTimeout = () => {
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(() => {
          if (!hasPopupBeenDisplayed()) {
            setShowPopup(true);
            document.cookie = "use-exit-intent=true; expires=30";
          }
        }, duration);
      };
      const handleActivity = () => {
        resetInactivityTimeout();
      };

      document.addEventListener("keydown", handleActivity);
      document.addEventListener("mousedown", handleActivity);
      resetInactivityTimeout();
      return () => {
        document.removeEventListener("keydown", handleActivity);
        document.removeEventListener("mousedown", handleActivity);
        clearTimeout(inactivityTimeout);
      };
    }
  }, [
    globalData.LoginForm,
    globalData.SignupForm,
    globalData.otpScreen,
    router.pathname,
  ]);

  return (
    <Fragment>
      <Head>
        <title>
          {!workshop_detail
            ? "Open Bonnet - "
            : router.pathname == "/profile" ||
              router.pathname == "sentry_sample_error"
            ? "NO CRAWL"
            : null}
          {metaData.title || title}
        </title>
        {router.pathname !== "/sign-up" &&
          mData &&
          mData.map((e) => <meta name={e.name} content={e.content} />)}
        {router.pathname !== "/sign-up" ? (
          <meta
            name="description"
            content={
              metaData.description ||
              `${
                workshop_detail ? workshop_detail : ""
              }Book your Car Service & Repair with UAE's Widest Network of Expert Service Providers. Let the Award-Winning OB Digital Platform Enhance your Service Experience`
            }
          />
        ) : null}
        <meta
          name="keywords"
          content={
            metaData.keywords ||
            "UAE car service, car repairs Dubai UAE, automotive services UAE, car maintenance UAE, car oil change Sharjah UAE, Car Inspection Abu Dhabi."
          }
        />
        <link
          href="https://unpkg.com/aos@2.3.1/dist/aos.css"
          rel="stylesheet"
        ></link>
        <link rel="canonical" href={canonicalURL} />
        <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              schema || {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                name: "Open Bonnet",
                image: "https://openbonnet.com/assets/logo.svg",
                "@id": "https://openbonnet.com/",
                url: "https://openbonnet.com/",
                telephone: "+8002274357",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: "DTEC - Dubai Silicon Oasis - Industrial Area",
                  addressLocality: "Dubai",
                  postalCode: "",
                  addressCountry: "+971",
                },
                sameAs: [
                  "https://www.facebook.com/openbonnetdotcom/",
                  "https://www.instagram.com/open_bonnet/",
                  "https://www.twitter.com/open_bonnet",
                  "https://www.linkedin.com/company/openbonnet/",
                  "https://www.youtube.com/channel/UCMw6hZugnW7DxCBDg2ZmKdg/videos",
                  "https://www.tiktok.com/@open_bonnet",
                  "https://www.snapchat.com/add/open_bonnet",
                  "https://api.whatsapp.com/send?phone=978002274357",
                ],
                openingHoursSpecification: [
                  {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ],
                    opens: "08:00",
                    closes: "20:00",
                  },
                ],
              }
            ),
          }}
        />
      </Head>
      {hideHeader ? (
        <Header
          locationOn={locationOn}
          carData={carData}
          askLocation={askLocation}
        />
      ) : (
        <>
          <NewHeader
            locationOn={locationOn}
            carData={carData}
            askLocation={askLocation}
          />
          <div
            className={
              router.pathname == "/services/[...slug]"
                ? "top-fix-service"
                : "top-fix"
            }
          ></div>
        </>
      )}
      {children}
      {globalData.isLoading && (
        <div className="loader">
          <img src="/assets/Logo12.webp" alt="logo" height={100} />
          <ThreeDots
            height="80"
            width="80"
            color="#0451be"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      )}

      {/* {!footerDisabledURLs.includes(router.pathname) && <Footer />} */}
      {/* {!hideFooter ? <Footer hideScrollToTop={hideScrollToTop} /> : null} */}
      {((router.pathname != "/checkout" && globalData.locationForm) ||
        (globalData.LoginForm && !isMobile) ||
        (globalData.SignupForm && !isMobile) ||
        (globalData.chooseCarForm &&
          router.pathname != "/services/[...slug]") ||
        (globalData.sosForm && !isMobile) ||
        globalData.searchForm ||
        globalData.manageCar ||
        globalData.showNotificatio) &&
      router.pathname != "/request/thank-you" ? (
        <div
          onClick={() => handleBackDrop()}
          className="dropdown-backdrop"
        ></div>
      ) : null}
      {showPopup ? <ExitIntentPopup handleClose={handleClose} /> : null}
      {!hideFooter ? (
        <NewFooter hideScrollToTop={hideScrollToTop} globalData={globalData} />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSearch: (stage) => {
      dispatch(toggleSearch(stage));
    },
    toggleSosForm: (stage) => {
      dispatch(toggleSosForm(stage));
    },
    toggleChooseCar: (stage) => {
      dispatch(toggleChooseCar(stage));
    },
    toggleSignupForm: (stage) => {
      dispatch(toggleSignupForm(stage));
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
    toggleLocation: (stage) => {
      dispatch(toggleLocation(stage));
    },
    toggleManageCar: (stage) => {
      dispatch(toggleManageCar(stage));
    },
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    toggleNotification: (flag) => {
      dispatch(toggleNotification(flag));
    },
    setOtpScreen: (flag) => {
      dispatch(setOtpScreen(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
