import { useRouter } from "next/router";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";

function SignUp({
  onSignUpSubmit,
  handleSubmit,
  register,
  errors,
  isSubmitting,
  setAuthBoxType,
  toggleLoginForm,
}) {
  return (
    <form
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "10%",
      }}
      onSubmit={handleSubmit(onSignUpSubmit)}
    >
      <div className="container text-center">
        <img src="/assets/user.svg" alt="logo" width={120} className="m-2" />
        <div className="d-flex text-left">
          <div
            className="auth-validation-div custom-validation-auth"
            style={{
              height: "40px",
              padding: "8px",
              margin: "10px 5px 5px 30px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: "1px solid #000",
              borderRadius: "20px",
              width: "50%",
            }}
          >
            <input
              type="text"
              placeholder=" First Name"
              style={{ border: "none" }}
              className="w-100"
              {...register("FullName", { required: true })}
              onKeyPress={(event) => {
                if (/\d/.test(event.key)) {
                  // test if the pressed key is a number
                  event.preventDefault(); // prevent the input of numeric values
                }
              }}
              onPaste={(event) => {
                const clipboardData =
                  event.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData("text");
                if (/\d/.test(pastedData)) {
                  // test if the clipboard contents contain numeric values
                  event.preventDefault(); // prevent the paste operation
                }
              }}
            // onPaste={(event) => {
            //   const clipboardData = event.clipboardData || window.clipboardData;
            //   const pastedData = clipboardData.getData('text');
            //   const textWithoutNumbers = pastedData.replace(/\d+/g, ''); // remove any numeric values from the pasted text
            //   document.execCommand('insertText', false, textWithoutNumbers); // paste the text without numeric values
            //   event.preventDefault(); // prevent the default paste operation
            // }}
            />
            {errors?.FullName && (
              <span className="text-danger auth-validation-span auth-validation-span-signup w-100">
                First Name is required
              </span>
            )}
          </div>
          <div
            className="auth-validation-div custom-validation-auth"
            style={{
              height: "40px",
              padding: "8px",
              margin: "10px 30px 5px 5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: "1px solid #000",
              borderRadius: "20px",
              width: "50%",
            }}
          >
            <input
              type="text"
              placeholder=" Last Name"
              style={{ border: "none" }}
              className="w-100"
              {...register("LastName", { required: true })}
              onKeyPress={(event) => {
                if (/\d/.test(event.key)) {
                  // test if the pressed key is a number
                  event.preventDefault(); // prevent the input of numeric values
                }
              }}
              onPaste={(event) => {
                const clipboardData =
                  event.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData("text");
                if (/\d/.test(pastedData)) {
                  // test if the clipboard contents contain numeric values
                  event.preventDefault(); // prevent the paste operation
                }
              }}
            />
            {errors?.LastName && (
              <span className="text-danger auth-validation-span auth-validation-span-signup w-100">
                Last Name is required
              </span>
            )}
          </div>
        </div>

        <div
          className="auth-validation-div custom-margin-validaiton text-left"
          style={{
            height: "40px",
            padding: "8px",
            margin: "5px 30px 18px",
            marginTop: isMobile ? "-2px" : "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #000",
            borderRadius: "20px",
          }}
        >
          <input
            type="email"
            placeholder=" Email"
            style={{ border: "none" }}
            className="w-100"
            {...register("Email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.Email && (
            <span className="text-danger auth-validation-span auth-validation-span-signup w-100">
              Email is required and format should be valid
            </span>
          )}
        </div>

        <div
          className="auth-validation-div text-left"
          style={{
            height: "40px",
            padding: "8px",
            margin: "5px 30px 18px",
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #000",
            borderRadius: "20px",
          }}
        >
          <div className="pr-2 d-flex align-items-center text-left">
            <svg
              width={31}
              height={30}
              viewBox="0 0 31 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                fill="#F0F0F0"
              />
              <path
                d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                fill="black"
              />
              <path
                d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                fill="#6DA544"
              />
              <path
                d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                fill="#A2001D"
              />
            </svg>
            +971
          </div>
          <input
            type="text"
            placeholder="Phone Number"
            onKeyDown={(e) => {
              const isCtrlKey = e.ctrlKey || e.metaKey; // Check if "ctrl" or "cmd" key is pressed
              const keyValue = e.key;
              const numericRegex = /^[0-9]*$/;
              const isEnterKey = e.key === "Enter";
              if (
                !(
                  numericRegex.test(keyValue) ||
                  isCtrlKey ||
                  keyValue === "Backspace" ||
                  isEnterKey
                )
              ) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              const pastedValue = e.clipboardData.getData("text");
              const numericRegex = /^[0-9]*$/;
              if (!numericRegex.test(pastedValue)) {
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength) {
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              }
            }}
            maxLength={9}
            style={{ border: "none" }}
            className="w-100"
            {...register("phoneNumber", {
              required: true,
              validate: (value) => value[0] === "5" && value.length === 9,
            })}
          />
          {errors.phoneNumber && (
            <span className="text-danger auth-validation-span auth-validation-span-signup w-100">
              Enter valid phone number
            </span>
          )}
        </div>
        <div
          className="auth-validation-div custom-margin-validaiton text-left"
          style={{
            height: "40px",
            padding: "8px",
            margin: "5px 30px",
            marginTop: isMobile ? "-2px" : "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #000",
            borderRadius: "20px",
          }}
        >
          <input
            type="text"
            placeholder=" Referral Code (Optional)"
            style={{ border: "none", color: "" }}
            className="w-100"
            {...register("RefferalCode", { required: false })}
          />
        </div>
        <button
          className="button-submit btn-login mt-3 text-white shine-animation-new"
          style={{
            border: "none",
            marginLeft: isMobile ? "10px" : "30px",
            background: "#000",
          }}
          type="submit"
          disabled={isSubmitting}
        >
          Sign Up
          {isSubmitting && (
            <Spinner animation="grow" role="status" className="ml-2" size="sm">
              <span className="visually-hidden"></span>
            </Spinner>
          )}
        </button>
        <a
          className="text-center"
          style={{ margin: "5px", cursor: "auto", color: "#8a3c90" }}
        >
          Already a member,
          <span
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              toggleLoginForm(true);
              setAuthBoxType("login");
            }}
          >
            {" "}
            Login
          </span>
        </a>
        <br />
        {/* <em>Refer your friend to collect OB Points </em> */}
      </div>
    </form>
  );
}

export default SignUp;
