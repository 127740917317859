import { handleClassNames } from "lib/cssHelpers";
import React, { useRef } from "react";
import styles from "@assets/css/header.module.css";
import { useState } from "react";
import { connect } from "react-redux";
import { fetchCars } from "redux/actions/profileAction";
import { setCartJourney, showMyAccount } from "redux/actions/cartAction";
import {
  getCarBrandModal,
  setLoader,
  toggleChooseCar,
  toggleManageCar,
} from "redux/actions/globalAction";
import { getModel } from "redux/actions/apiActions";
import { getBrand } from "api/home";
import { clearLoginData } from "redux/actions/loginAction";
import { useEffect } from "react";
import { packageSize, workshopPurchase } from "../../lib/config";
import { useRouter } from "next/router";
import { saveCart } from "lib/cart";
import ChooseCar from "components/Header/ChooseCar";

const CarSection = ({
  profile,
  cart,
  fetchCars,
  loginData,
  getCarBrandModal,
  apiData,
  globalData,
  showMyAccount,
  handleSelectCar,
  showCar,
  setShowCar,
  toggleManageCar,
  toggleChooseCar,
  handleChooseCar,
  setLoader,
  setCartJourney,
}) => {
  const [selectedCar, setCar] = useState({});
  const [currentCar, setCurrentCar] = useState({});
  const router = useRouter();
  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
  const handleAddCar = () => {
    setLoader(true);
    if (router.pathname == "/profile/my-car") {
      setLoader(false);
      localStorage.setItem("addCar", true);
    }
    toggleManageCar(false);
    router.push({
      pathname: "/profile/my-car",
      query: { showPop: true },
    });
  };

  useEffect(async () => {
    const car_data = globalData.carData
      ? globalData.carData
      : JSON.parse(localStorage.getItem("car_data") || JSON.stringify(""));
    setCurrentCar(car_data ? car_data : null);
    await fetchCars();
  }, [globalData.carData, loginData?.userAuth]);

  useEffect(async () => {
    if (!(cart.workshop && cart.products)) {
      if (
        !localStorage.getItem("car_data") &&
        Array.isArray(globalData.carData)
      ) {
        selectCar(profile.cars[0]);
      }
    }
  }, [profile.cars]);

  const selectCar = (carData) => {
    if (carData && !(cart.workshop && cart.products)) {
      setCar(carData);
      getCarBrandModal(
        carData.CarCompany.CompanyName,
        carData.CarModel.Name,
        carData.CarCompany.ImgUrl,
        carData.CarModel.ImgUrl,
        carData.CarCompany.Id,
        carData.CarModel.Id,
        carData.CarModel.Slug
      );
      setShowCar(false);
      saveCart(cart, globalData, apiData);
      showMyAccount(false);
      toggleManageCar(false);

      if (router.pathname === `/services/[...slug]`) {
        const carName =
          `${carData.CarCompany.CompanyName} ${carData.CarModel.Name}`
            .trim()
            .split(" ")
            .join("-")
            .toLowerCase()
            .split(".")
            .join("");

        router.push(
          `/services/${
            globalData.locationData?.data.EmirateAreaSlug || "dubai"
          }/${router.query.slug[1]}/${carName ?? "car"}`
        );
      }
    }
  };

  return (
    <>
      {/* <div className={handleClasses("dropdown-backdrop")}></div> */}
      <div
        style={{ cursor: "pointer" }}
        className={`dropdown ${handleClasses(
          "ad-more-car active-car-val show"
        )}`}
      >
        <div
          onClick={(event) => {
            handleSelectCar();
            if (
              dropdownRef.current &&
              !dropdownRef.current.contains(event.target)
            ) {
              toggleManageCar(false);
            }
          }}
          className={handleClasses("ad-car-dropdown")}
          id="dropdownCar"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <label>
            <img
              className={handleClasses("brimage")}
              src={currentCar?.brandUrl || "/assets/header/demo.webp"}
              alt="brand"
            />
          </label>
          <strong>
            {profile?.cars?.length == 0 ? "Add Car" : "Manage Car"}
          </strong>
          <span>
            {currentCar && currentCar.brand
              ? `${currentCar.brand} - ${currentCar.modal}`
              : "fetching.."}
          </span>
          <i className="fa-solid fa-caret-down" />
        </div>
        {globalData.manageCar ? (
          <div
            className={`dropdown-menu show ${handleClasses(
              "dropdown-menu show"
            )}`}
            aria-labelledby="dropdownMenuButton"
            ref={dropdownRef}
          >
            <div className={handleClasses("yp-car-listing")}>
              {profile.cars?.length > 0 ? (
                <ul>
                  {profile.cars
                    .filter((car) => car?.IsActive)
                    .map((car) => {
                      return (
                        <li
                          onClick={() => {
                            localStorage.removeItem("isQuoterequest");
                            setCartJourney(workshopPurchase);
                            selectCar(car);
                          }}
                        >
                          <div className={handleClasses("image-lf-cr")}>
                            <img
                              src={
                                car?.CarModel?.ImgUrl ||
                                "/assets/header/demo.webp"
                              }
                              alt="car-image"
                            />
                          </div>
                          <div className={handleClasses("image-rg-cr")}>
                            <h2>{car.CarModel?.Name}</h2>
                            <h5>
                              <span>Brand</span> : {car.CarCompany?.CompanyName}
                            </h5>
                          </div>
                          <i
                            className={handleClasses(
                              "fa-regular fa-circle-check"
                            )}
                          />
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <p
                  style={{
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="text-center px-2"
                >
                  {/* No Car Added to Account */}
                  No cars have been added to this account yet.
                </p>
              )}
              <div className={handleClasses("car-mod-action")}>
                {profile.cars?.length == 0 ? (
                  <button
                    style={{ padding: "10px 10px" }}
                    onClick={() => handleAddCar()}
                    className={`btn ${handleClasses("adcar")}`}
                  >
                    Add Car
                  </button>
                ) : (
                  <>
                    <button
                      style={{ padding: "10px 10px" }}
                      onClick={() => handleChooseCar()}
                      className={`btn ${handleClasses("adcar")}`}
                    >
                      Change Car
                    </button>
                  </>
                )}
                {/* {profile.cars.length > 0 ? ( */}
                <button
                  onClick={() => {
                    toggleManageCar(false);
                    router.push("/profile/my-car");
                  }}
                  className={`btn ${handleClasses("mgrcar")}`}
                >
                  Manage Car
                </button>
                {/* ) : ( */}
                {/* <button
                    style={{ padding: "10px 10px" }}
                    onClick={() => handleChooseCar()}
                    className={`btn ${handleClasses("mgrcar")}`}
                  >
                    Change Car
                  </button> */}
                {/* )} */}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getBrand: () => {
      dispatch(getBrand());
    },
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug
        )
      );
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    showMyAccount: (data) => {
      dispatch(showMyAccount(data));
    },
    toggleManageCar: (data) => {
      dispatch(toggleManageCar(data));
    },
    toggleChooseCar: (data) => {
      dispatch(toggleChooseCar(data));
    },
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setCartJourney: (stage) => {
      dispatch(setCartJourney(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarSection);
