import React, { useEffect } from "react";
import { Container, Row, Col, Alert, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import AppBar from "./elements/AppBar";
import Sidebar from "./elements/Sidebar";
import { setLoader } from "redux/actions/globalAction";
import API from "api";
import toast from "react-hot-toast";
import { useState } from "react";

const ProfileLayout = ({ slug, setSlug, children, loginData, setLoader }) => 
{
  const [loading, setLoading] = useState(false);
  
  const handleSendEmail = async () => {

    setLoading(true);
    toast.dismiss();

    let customerId = JSON.parse(localStorage.getItem("customerId"));

    await API.post(`v2/api/customers/${customerId}/resend-email-verification`)
    .then((res) => {        
      if (res?.data?.IsSuccess) {
        toast.success("Verification Email Resent Successfully.");
      } else {
        toast.error("Something went wrong! Please retry.");
      }        
    })
    .catch((err) => {
      toast.error("Something went wrong.");        
    });

      setLoading(false);
  };

  useEffect(() => {
    setLoader(false);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="no-space-block" style={{ padding: "20px" }}>
          <Col sm={0} md={0} lg={2} className="hide-side-mobile">
            <Sidebar slug={slug} setSlug={setSlug} />
          </Col>
          <Col  sm={12} md={12} lg={7} style={{ display: "flex",alignItems: "center",flexDirection: "column" }}>
            {loginData?.userAuth ?
              loginData?.userAuth?.EmailConfirmed == true ? <></> :            
                <Alert variant="warning" className="mt-3 mt-sm-0">
                  <strong>Email Verification is Pending!</strong> Verify your
                  email to get updated on our services.{" "}
                  <button
                    onClick={handleSendEmail}
                    className="btn alert-warning"
                    style={{
                      cursor: "pointer",
                      padding: 0,
                      margin: 0,
                      border: "none",
                      textDecoration: "underline",
                      marginTop: "-5px",
                    }}
                    disabled={loading}
                  >
                    Resend Email{" "}
                    {loading ? (
                      <Spinner
                        animation="grow"
                        role="status"
                        className="ml-2"
                        size="sm"
                        style={{
                          width: "10px",
                          height: "10px",
                          marginBottom: "4px",
                        }}
                      >
                        <span className="visually-hidden"></span>
                      </Spinner>
                    ) : null}
                  </button>
                </Alert>
            : <></> 
            }
            {children}
          </Col>
          <Col sm={12} md={12} lg={3}>
            <AppBar />
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout);