export default {
  PHONE: require('../public/assets/phone.svg'),
  LOGO: require('../public/assets/Logo12.webp'),
  WHATSAPP: require('../public/assets/whatsapp.svg'),
  USER: require('../public/assets/user.svg'),
  COIN: require('../public/assets/header/ob-coin.webp'),
  CART: require('../public/assets/cart.webp'),
  SERACH: require('../public/assets/search.svg'),
  CAR: require('../public/assets/car.webp'),
  BELL: require('../public/assets/bell.webp'),
  CALL: require('../public/assets/images/call.webp'),
  ICON: require('../public/assets/images/Icon.webp'),

  // main-banner
  TARGET: require('../public/assets/images/target.svg'),
  MY_CAR: require('../public/assets/images/my_car.svg'),
  BANNER: require('../public/assets/banner.webp'),
  SOS: require('../public/assets/sos.svg'),
  SOS_ICON: require('../public/assets/images/sos.webp'),
  PHONE: require("../public/assets/phone.svg"),
  LOGO: require("../public/assets/Logo12.webp"),
  WHATSAPP: require("../public/assets/whatsapp.svg"),
  USER: require("../public/assets/user.svg"),
  COIN: require("../public/assets/coin.svg"),
  SERACH: require("../public/assets/search.svg"),
  CALL: require("../public/assets/images/call.webp"),
  ICON: require("../public/assets/images/Icon.webp"),

  // main-banner
  TARGET: require("../public/assets/images/target.svg"),
  MY_CAR: require("../public/assets/images/my_car.svg"),
  BANNER: require("../public/assets/banner.webp"),
  SOS: require("../public/assets/sos.svg"),

  // offer-page
  OIL_OFFER: require("../public/assets/oil-offer.webp"),

  // brand-page
  BUBBLE: require("../public/assets/images/bubble.webp"),
  SORT_ICON: require("./assets/images/sort.svg"),
};
