import React, { useState, useEffect } from "react";
import useWindowDimensions from "../WindowSize";
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";
import { isMobile } from "react-device-detect";
import Nav from "react-bootstrap/Nav";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import ChangeLocation from "./ChangeLocation";

const Location = ({ style, style1, text }) => {
  const { height, width } = useWindowDimensions();
  const [showLocation, setShowLocation] = useState(false);

  useEffect(() => {}, []);

  return (
    <div className="position-relative">
      {!isMobile && width > 850 && (
        <Nav.Item>
          <Nav.Link onClick={() => setShowLocation(!showLocation)}>
            <span className={style} style={style1}>
              <span className="your-location">Your Car Location</span>
              {text} <button className="btn btn-primary">Change</button>
            </span>
          </Nav.Link>
        </Nav.Item>
      )}
      {showLocation && <ChangeLocation setShowLocation={setShowLocation} />}
    </div>
  );
};

export default Location;
