import API from "api";
import {
  GET_ERROR,
  LOGIN_DATA,
  RESET_STORE,
  USER_AUTH,
  FORMAT_RESET_STORE,
  SET_PROFILE_LOADING,
} from "./types";
import axios from "axios";
import { useRouter } from "next/dist/client/router";
import { fetchCars } from "./profileAction";

let header = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getLoginData = (phone) => async (dispatch) => {
  localStorage.setItem("login_access", JSON.stringify(phone));
  dispatch({
    type: LOGIN_DATA,
    payload: phone,
  });
};

export const clearLoginData = () => async (dispatch) => {
  localStorage.clear("login_access");
  localStorage.clear("customerId");
  dispatch({
    type: LOGIN_DATA,
    payload: [],
  });
};

export const resetStore = () => async (dispatch) => {
  localStorage.clear();
  dispatch({ type: RESET_STORE });
  dispatch({ type: FORMAT_RESET_STORE });
};

// export const getUserAuth = ( data ) => async( dispatch ) => {
//         console.log('user auth redux',data)
//         localStorage.setItem("login_data",JSON.stringify(data));
//         dispatch({
//             type: USER_AUTH,
//             payload: data
//         });
// }

export const clearUserAuth = () => async (dispatch) => {
  dispatch({
    type: USER_AUTH,
    payload: [],
  });
};

export const getUserAuth = () => async (dispatch) => {

  let token = await JSON.parse(localStorage.getItem("login_access"));

  if (token) {
    dispatch({ type: SET_PROFILE_LOADING, payload: true });
    try {
      const { data } = await API.get(`v2/api/customers/auth`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      dispatch({
        type: LOGIN_DATA,
        payload: data.Data,
      });
      dispatch({
        type: USER_AUTH,
        payload: data.Data,
      });
      
      localStorage.setItem("customerId",data.Data.Id)
      dispatch(fetchCars())
      dispatch({ type: SET_PROFILE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_PROFILE_LOADING, payload: false });

      console.log("eeee ", error);
    }
  }
};

export const setAuth = (data) => async (dispatch) => {
  
  dispatch({
    type: LOGIN_DATA,
    payload: data,
  });
};

export const verifyOtp = (data) => async (dispatch) => {
  return await new Promise(async (resolve, reject) => {
    await API.post(`token`, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        localStorage.setItem("login_access", JSON.stringify(res.data));
        dispatch({
          type: LOGIN_DATA,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  // try {
  //     const res = await axios.post(
  //         `${process.env.NEXT_PUBLIC_API_URL}token`,
  //         data,
  //         {
  //             headers: { "Content-Type": "application/x-www-form-urlencoded" }
  //         },
  //     )
  //     localStorage.setItem("login_access",JSON.stringify(res.data) )
  //     dispatch({
  //         type: LOGIN_DATA,
  //         payload: res.data
  //     });
  //     return res.data;
  // } catch (error) {
  //     return error;
  // }
};
