import { useRouter } from "next/dist/client/router";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Link from "next/link";
import { connect } from "react-redux";
import { clearLoginData, resetStore } from "../../../redux/actions/loginAction";
import { isMobile } from "react-device-detect";
import { FaBars, FaTimes } from "react-icons/fa";
import { fetchForActiveOrders, fetchOrders } from "redux/actions/profileAction";

const Sidebar = ({
  clearLoginData,
  resetStore,
  profile,
  fetchOrders,
  fetchForActiveOrders,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const initialAnimation = {
    order: false,
    quotation: false,
  };
  const [blinkingAnimation, setBlinkingAnimation] = useState(initialAnimation);
  const router = useRouter();

  const logout = async () => {
    await clearLoginData();
    await resetStore();
    await router.push("/");
  };

  const url = router.pathname;

  useEffect(() => {
    fetchOrders();
    fetchForActiveOrders();
  }, []);

  useEffect(() => {
    const animation = { ...initialAnimation };
    const orders = profile.forActiveOrders;
    // const quotations = profile.activeQuotations;
    if (orders && orders?.Data && orders?.Data?.CustomerPurchaseOrder?.Id) {
      animation.order = true;
    }

    if (orders && orders?.Data && orders?.Data?.CustomerQuotationRequest?.Id) {
      animation.quotation = true;
    }

    setBlinkingAnimation(animation);
  }, [profile.forActiveOrders]);
  return (
    <>
      {isMobile ? (
        <div
          style={{
            background: "#f3f4ff",
            width: "100%",
            height: "100%",
            padding: "20px",
          }}
        >
          <Col className={showMenu ? "mb-3 px-0 w-100" : "px-0 w-100"}>
            <div className="d-flex justify-content-between align-items-center w-100 h-100">
              <p className="m-0">Menu</p>{" "}
              {showMenu ? (
                <FaTimes onClick={() => setShowMenu(false)} />
              ) : (
                <FaBars onClick={() => setShowMenu(true)} />
              )}
            </div>
          </Col>
          {showMenu && (
            <>
              <Link href="/profile/dashboard">
                <Row
                  className={`${
                    url == "/profile/dashboard"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col
                    onClick={() => router.push("")}
                    className="profile-sidebar-center"
                  >
                    My Dashboard
                  </Col>
                </Row>
              </Link>
              <Link href="/profile">
                <Row
                  className={`${
                    url == "/profile"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col className="profile-sidebar-center">Profile</Col>
                </Row>
              </Link>
              <Link href="/profile/orders">
                <Row
                  className={`${
                    url == "/profile/orders"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col className="profile-sidebar-center">Orders</Col>
                </Row>
              </Link>
              <Link href="/profile/quotation">
                <Row
                  className={`${
                    url == "/profile/quotation"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col className="profile-sidebar-center">Quotation</Col>
                </Row>
              </Link>
              <Link href="/profile/ob-points">
                <Row
                  className={`${
                    url == "/profile/ob-points"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col className="profile-sidebar-center">OB Points</Col>
                </Row>
              </Link>
              <Link href="/profile/my-address">
                <Row
                  className={`${
                    url == "/profile/my-address"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col className="profile-sidebar-center">My Address</Col>
                </Row>
              </Link>
              <Link href="/profile/my-car">
                <Row
                  className={`${
                    url == "/profile/my-car"
                      ? "profile-sidebar-button-active"
                      : "profile-sidebar-button"
                  }`}
                >
                  <Col className="profile-sidebar-center">My Cars</Col>
                </Row>
              </Link>
              <Row className={`profile-sidebar-button`} onClick={logout}>
                <Col className="profile-sidebar-center">Logout</Col>
              </Row>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            background: "#f3f4ff",
            width: "100%",
            height: "100%",
            padding: "20px",
          }}
        >
          <Link href="/profile/dashboard">
            <Row
              className={`${
                url == "/profile/dashboard"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">My Dashboard</Col>
            </Row>
          </Link>
          <Link href="/profile">
            <Row
              className={`${
                url == "/profile"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">Profile</Col>
            </Row>
          </Link>
          <Link href="/profile/orders">
            <Row
              className={`${
                url == "/profile/orders"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">
                <span className="position-relative">
                  Orders
                  {blinkingAnimation.order && (
                    <div className="progress-waves">
                      <span />
                    </div>
                  )}
                </span>
              </Col>
            </Row>
          </Link>
          <Link href="/profile/quotation">
            <Row
              className={`${
                url == "/profile/quotation"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">
                <span className="position-relative">
                  Quotation
                  {blinkingAnimation.quotation && (
                    <div className="progress-waves" style={{ right: '-45px'}}>
                      <span />
                    </div>
                  )}
                </span>
              </Col>
            </Row>
          </Link>
          <Link href="/profile/ob-points">
            <Row
              className={`${
                url == "/profile/ob-points"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">OB Points</Col>
            </Row>
          </Link>
          <Link href="/profile/my-address">
            <Row
              className={`${
                url == "/profile/my-address"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">My Address</Col>
            </Row>
          </Link>
          <Link href="/profile/my-car">
            <Row
              className={`${
                url == "/profile/my-car"
                  ? "profile-sidebar-button-active"
                  : "profile-sidebar-button"
              }`}
            >
              <Col className="profile-sidebar-center">My Cars</Col>
            </Row>
          </Link>
          <Row className={`profile-sidebar-button`} onClick={logout}>
            <Col className="profile-sidebar-center">Logout</Col>
          </Row>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    resetStore: () => {
      dispatch(resetStore());
    },
    fetchOrders: () => {
      dispatch(fetchOrders());
    },
    fetchForActiveOrders: () => {
      dispatch(fetchForActiveOrders());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
