import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { connect } from 'react-redux';
import { getProfileData } from '../../../../redux/actions/profileAction';
import AvatarList from './AvatarList';
import EditForm from './EditForm';

const ProfilePage = ({ loginData, profile, getProfileData }) => {

    const [profileUrl, setProfileUrl] = useState(null);
    const [avatar, setAvatar] = useState();

    useEffect(async () => {
        await getProfileData();

    }, [loginData]);

    useEffect(() => {
        setProfileUrl(profile.info.ImageUrl)
    }, [profile])

    return (
        <>
            <Row style={{ background: '#FCFCFC', borderRadius: '20px', padding: '30px 10px 30px 30px', border: '1px dashed #848484' }}>
                <Col sm={5} style={{ display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', borderRight: '1px solid #D9D9D9' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '140px', width: '140px', background: '#f3f4ff', borderRadius: '70px' }}>
                        <img src={profileUrl != null ? profileUrl : "/assets/user.svg"} alt="user" height="100%" width="100%" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", fontSize: '16px', fontWeight: '500', cursor: 'pointer', padding: '10px' }}>
                        {profile.info.FirstName}
                    </div>
                </Col>
                <Col sm={7}>
                    <AvatarList profileUrl={profileUrl} setProfileUrl={setProfileUrl} setAvatar={setAvatar} />
                </Col>
            </Row>
            <EditForm profileUrl={profileUrl} avatar={avatar} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        globalData: state.globalData,
        apiData: state.apiData,
        loginData: state.loginData,
        profile: state.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileData: () => { dispatch(getProfileData()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
