import React, { useEffect, useState } from "react";
import Router from "next/router";
import { isLoggedIn } from "lib/auth";

const authenticatedRoute = (Component = null, options = {}) => {
  
  const AuthenticatedRoute = (props) => {

    const [loading, setLoading] = useState(true);

    const handleProfileLoginRedirection = () => {     
      isLoggedIn().then(()=>{
        if (JSON.parse(localStorage.getItem("login_access"))) {
          setLoading(false);
        }        
      })
      .catch(err=> {
        if (typeof window === "undefined") return null;
         Router.push(options.pathAfterFailure || "/"); })
    }

    handleProfileLoginRedirection()

    if (loading) {
      return <div />;
    }

    return <Component {...props} />;
  };

  return AuthenticatedRoute;
};

export default authenticatedRoute;
