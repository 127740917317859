import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";


const CommonPopup = ({
  popUpSlug,
  show,
  onHide,
  message
}) => {
  const router = useRouter();

  const handleRedirect = () => {
    router.push(popUpSlug);
  };

  const handleClose = () => {
    onHide();
    handleRedirect();
  };

  return (
    <Modal show={show} centered>
      {/* onHide={onHide} */}
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default CommonPopup;
