import React from "react";
import styles from "@assets/css/header.module.css";
import { handleClassNames } from "lib/cssHelpers";
import Search from "./search";
import SideBar from "./side-bar";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { connect, useDispatch } from "react-redux";
import useWindowDimensions from "../WindowSize";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { getModel, getVariants } from "../../redux/actions/apiActions";
import {
  getCarBrandModal,
  fetchLocationData,
  getLocationData,
  setLoginPopup,
  setCarPopup,
  setLoader,
  toggleSearch,
  toggleSosForm,
  toggleChooseCar,
  toggleSignupForm,
  toggleLoginForm,
  toggleLocation,
  toggleManageCar,
  setAccessLocation,
  setOtpScreen,
  setSelctedId,
  setLayer,
} from "../../redux/actions/globalAction";
import {
  clearLoginData,
  getUserAuth,
  setAuth,
} from "../../redux/actions/loginAction";
import { fetchCars } from "../../redux/actions/profileAction";
import {
  setCartAddons,
  setCartJobItems,
  setCartJourney,
  setCartProducts,
  setCartStage,
  setCartWorkshop,
  setShowMobileIstand,
} from "../../redux/actions/cartAction";
import { gtm_ecommerce_view_cart } from "lib/GA4_tag";
import { fetchBrand, fetchCustomerCart } from "../../actions/homePage";
import LocationSection from "./location-section";
import CarSection from "./car-section";
import ChooseCar from "components/Header/ChooseCar";
import ChooseCarMobile from "components/common/ChooseCarMobile";
import Notification from "components/Header/Notification";
import { getProfileData } from "../../redux/actions/profileAction";
import { toggleUpdateProfile } from "../../redux/actions/profileAction";
import { GetInfo } from "lib/Location";
import { workshopPurchase } from "lib/config";
import UserAuthentication from "../auth/index";
import { IoIosArrowBack } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import { route } from "next/dist/server/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { TiShoppingCart } from "react-icons/ti";
import { delBasePath } from "next/dist/shared/lib/router/router";

const NewHeader = ({
  cart,
  profile,
  loginData,
  apiData,
  globalData,
  getCarBrandModal,
  getUserAuth,
  fetchLocationData,
  getLocationData,
  setShowMobileIstand,
  setLoader,
  toggleLocation,
  toggleLoginForm,
  toggleSosForm,
  toggleChooseCar,
  toggleSignupForm,
  toggleManageCar,
  getVariants,
  setAccessLocation,
  fetchCustomerCart,
  toggleUpdateProfile,
  getProfileData,
  setSelctedId,
  setCartJourney,
  setCartStage,
  setLayer,
}) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [login, setLogin] = useState(false);
  const { height, width } = useWindowDimensions();
  const [carHide, setCarHide] = useState(false);
  const [logHide, setLogHide] = useState(false);
  const [ob, setOb] = useState("");
  const router = useRouter();
  const dispatch = useDispatch();

  const {
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const [showLocaiton, setShowLocaiton] = useState(false);
  const [showCar, setShowCar] = useState(false);
  const [showLoginSignUp, setShowLoginSignUp] = useState(false);
  const [authBoxType, setAuthBoxType] = useState("");
  const [categories, setCategories] = useState([]);
  const [isCurrentCar, setIsCurrentCar] = useState({});
  const [showCarPopUp, setShowCarPopUp] = useState(false);
  const [isMobieSearch, setIsMobieSearch] = useState(false);
  const [currentCateogory, setCurrentCateogory] = useState("");
  const [workshopCategory, setworkshopCategory] = useState([]);
  const [isShowCar, setIsShowCar] = useState(false);
  const [loading, setLoading] = useState(false);

  const profileImageUrl =
    profile?.info?.ImageUrl ||
    loginData?.userAuth?.Avatar ||
    "/assets/header/user-dummy.webp";

  useEffect(() => {
    getProfileData();
    toggleUpdateProfile(false);
  }, [loginData?.userAuth, profile.IsProfileUpdate]);

  useEffect(() => {
    // console.log(apiData.categories)
    setCategories(apiData.categories.filter((item) => item.ID != 17));
    if (globalData.currentCategory) {
      if (cart.workshop && cart.workshop.Id == 1001) {
        setworkshopCategory(
          apiData.categories.filter((item) => {
            if (item.ID == 4 || item.ID == 6 || item.ID == 15) {
              return true;
            } else return false;
          })
        );
      } else {
        const filter = globalData.currentCategory.filter(
          (item) => item.ID !== 8 || item.ID !== 17
        );
        setworkshopCategory(
          localStorage.getItem("isWorkshopCollection")
            ? filter
            : globalData.currentCategory.filter((item) => item.ID !== 17)
        );
      }
    }
    const car_data = globalData.carData
      ? globalData.carData
      : JSON.parse(localStorage.getItem("car_data") || JSON.stringify(""));
    setIsCurrentCar(car_data ? car_data : null);
  }, [
    apiData.categories,
    globalData.carData,
    globalData.currentCategory,
    loginData?.userAuth,
    cart.workshop,
  ]);

  const hideHeader =
    router.pathname == "/request" ||
    router.pathname == "/sign-up" ||
    router.pathname == "/offer-inquiry" ||
    router.pathname == "/survey/user-feedback" ||
    router.pathname == "/report/inspections/[id]" ||
    router.pathname == "/sign-up" ||
    router.pathname == "/payment/[id]";
  const isCheckout = router.pathname == "/checkout";

  const handleSidebar = () => {
    setShowSideBar(!showSideBar);
    toggleManageCar(false);
    toggleLocation(false);
  };

  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };

  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const cartRef = useRef();

  const handleLogin = () => {
    reset();
    setShowSignUp(false);
    setShowLogin(!showLogin);
    setShowCar(false);
    setShowLocaiton(false);
    setAuthBoxType("login");
    setShowLoginSignUp(!showLoginSignUp);
    toggleLoginForm(true);
    toggleChooseCar(false);
    toggleSosForm(false);
  };

  const handleChooseCar = () => {
    setIsShowCar(true);
    toggleManageCar(false);
  };
  const handleSignUp = () => {
    reset();
    setShowLogin(false);
    setShowSignUp(!showSignUp);
    setShowCar(false);
    setShowLocaiton(false);
    setAuthBoxType("signup");
    setShowLoginSignUp(!showLoginSignUp);
    toggleSignupForm(true);
    toggleChooseCar(false);
  };

  const handleSelectCar = () => {
    console.log("handleSelectCar");
    if (!login) {
      setShowCarPopUp(!showCarPopUp);
      toggleLocation(false);
      return;
    }
    // setShowCar(!showCar);
    toggleManageCar(!showCar);
    toggleLocation(false);
    setShowLocaiton(false);
  };

  const handleToggleLocation = () => {
    if (globalData.locationForm) {
      toggleLocation(false);
      setShowSignUp(false);
      setShowLogin(false);
      setShowCarPopUp(false);
      return;
    }
    toggleManageCar(false);
    toggleLocation(true);
    setShowSignUp(false);
    setShowLogin(false);
    setShowCarPopUp(false);
    setShowCar(false);
  };

  useEffect(() => {
    getUserAuth();
    fetchLocationData();
    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (globalData.showLoginPop) {
      setLogHide(globalData.showLoginPop);
      setAuthBoxType("login");
    }
  }, [globalData.showLoginPop]);

  useEffect(async () => {
    if (
      !localStorage.getItem("car_data") &&
      profile.cars.length > 0 &&
      !(cart.workshop && cart.products)
    ) {
      let carData = profile.cars[0];

      getCarBrandModal(
        carData.CarCompany.CompanyName,
        carData.CarModel.Name,
        carData.CarCompany.ImgUrl,
        carData.CarModel.ImgUrl,
        carData.CarCompany.Id,
        carData.CarModel.Id,
        carData.CarModel.Slug
      );
    }
  }, [profile.cars]);

  useEffect(() => {
    console.log("ROUTER REFFEREL CODE", router.query.referrelCode);
    if (router.query.referrelCode) handleSignUp();
  }, [router.query]);

  useEffect(() => {
    const cartElement = cartRef.current;

    if (isShowCart && cart.products.length > 0) {
      if (cartElement) {
        cartElement.classList.add("shake-animation");

        const animationDuration = 500;
        setTimeout(() => {
          cartElement.classList.remove("shake-animation");
        }, animationDuration);
      }
    }
  }, [cart.products.length]);

  useEffect(() => {
    localStorage.removeItem("geolocation-called");
    setTimeout(() => {
      const hasLocationPermission = localStorage.getItem(
        "hasLocationPermission"
      );
      if (!globalData.accessLocation && !hasLocationPermission) {
        GetInfo(getinfoCallback);
      }
    }, 5000);
  }, []);

  const getinfoCallback = (lat, lang) => {
    getLocationData(lat, lang, "");
  };

  const getCurrentLocation = () => {
    locateCurrentPosition()
      .then((position) => {
        const lat = Number(position.coords.latitude);
        const lang = Number(position.coords.longitude);
        getLocationData(lat, lang, "");
      })
      .catch((e) => {
        GetInfo(getinfoCallback);
      });
  };

  const locateCurrentPosition = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          localStorage.setItem("hasLocationPermission", "granted");
          setAccessLocation("granted");
          resolve(position);
        },
        (error) => {
          console.log(error.message);
          localStorage.setItem("hasLocationPermission", "denied");
          setAccessLocation("denied");
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000,
        }
      );
    });

  useEffect(() => {
    dispatch(fetchBrand());
    setLogin(
      loginData.login != null &&
        loginData.login != undefined &&
        loginData.login != ""
        ? true
        : false
    );
    setOb(loginData.userAuth?.ObPointAmount || 0);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loginData.userAuth]);

  const handleScroll = () => {};

  useEffect(async () => {
    const isCartClear = localStorage.getItem("isCartClear");
    if (
      loginData &&
      loginData.userAuth &&
      (!isCheckout || localStorage.getItem("fetch-cart")) &&
      !isCartClear
    ) {
      localStorage.removeItem("headerLogin");
      localStorage.removeItem("isCartClear");
      fetchCustomerCart();
    }
  }, [loginData?.userAuth]);

  let productLink = "";

  if (cart.products.length > 0) {
    productLink = apiData?.categories.filter(
      (item) => item.ID == cart.products[0]?.JourneyCategoryId
    )[0].Slug;
  }

  const handleCategory = (item, isTrue = false) => {
    setLoader(true);
    if (router.pathname == "/services/[...slug]") {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
    setCurrentCateogory(item.Name);
    if (!(cart && cart.workshop)) {
      setCartStage(null);
      setCartProducts([]);
      setCartJobItems([]);
    }
    getVariants(item.ID, globalData?.carData?.modelId, cart.workshop?.Id);
    localStorage.setItem("selected-service", item.Slug);
    localStorage.setItem("selected-service-title", item?.Name);
    router.push(
      `/services/${
        globalData?.locationData?.data?.EmirateAreaSlug || "dubai"
      }/${item.Slug}`
    );
  };

  const onCategoryClick = () => {
    setCartJourney(workshopPurchase);
    if (cart.workshop && cart.products?.length) {
      setCartStage(2);
    } else {
      setCartStage(null);
    }
  };

  const workshopURL =
    router.query.slug &&
    cart.workshop &&
    router.query.slug[3] == cart.workshop?.Slug;

  const handleCategoryProduct = (single) => {
    if (single.ID == currentCateogory || single.IsDisabled) {
      onCategoryClick();
      return;
    }
    setLayer(false);
    setSelctedId(single.ID);
    router.push(
      `/services/${globalData.locationData?.data?.EmirateAreaSlug || "dubai"}/${
        single.Slug
      }/${
        router.query?.slug?.length && router?.query?.slug[2]
          ? router?.query?.slug[2]
          : ""
      }${workshopURL ? "/" + router.query.slug[3] : ""}`
    );

    localStorage.setItem("selected-service", single?.Slug);
    localStorage.setItem("selected-service-title", single?.Name);
    getVariants(single.ID, globalData?.carData?.modelId, cart.workshop?.Id);
    onCategoryClick();
  };

  useEffect(() => {
    setCurrentCateogory(globalData.selected_id);
  }, [globalData.selected_id]);

  const handleCart = (value = false) => {
    if (isMobile) {
      setShowMobileIstand(true);
      setCartStage(1);
    }
    setLoader(true);
    if (router.pathname == "/services/[...slug]") {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
      return;
    }
    localStorage.setItem("isHeader", true);
    console.log(cart.products);
    viewCart();
    router.push(`/services/fujairah-mina-al-fajer/${productLink}`);
  };
  const viewCart = () => {
    if (cart && cart.jobItems && cart.jobItems.length > 0) {
      const items = cart.jobItems.map((item, index) => {
        let offer;
        const isOfferApplied =
          cart.appliedCartOffers?.variantId == item?.JourneyVariantId;
        if (isOfferApplied) {
          offer = item.Offers.filter(
            (e) => e.PromotionId == cart.appliedCartOffers?.PromotionId
          );
        }
        return {
          item_id: item.JourneyVariantId,
          item_name: item.JourneyVariantName,
          coupon: offer?.length
            ? offer[0]?.PromotionCode || offer[0].PromotionId
            : null,
          price: item.Cost,
          index: index,
          quantity: 1,
        };
      });
      const totalCost = items.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.price;
      }, 0);

      const event = {
        currency: "AED",
        value: totalCost,
        items: items,
      };
      gtm_ecommerce_view_cart(event);
    }
  };
  const handleSearchClick = () => {
    setIsMobieSearch(!isMobieSearch);
  };
  const isShowCart =
    (cart.products && !isCheckout && !hideHeader && cart.products?.length) ||
    (cart.addedPackages &&
      cart.addedPackages?.length &&
      router.pathname != "/services/[...slug]" &&
      router.pathname != "/checkout/thank-you");

  const closePopups = () => {
    setShowCar(false);
    setShowCarPopUp(false);
    setShowLocaiton(false);
    setShowSignUp(false);
    setShowLogin(false);
    handleSearchClick();
  };

  const handleworkshop = () => {
    setLoader(true);
    if (router.pathname == "/workshop-network") {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }
    router.push("/workshop-network");
  };
  const commonStyles = {
    cursor: "pointer",
    position: "relative",
  };
  const defaultStyles = {
    cursor: "pointer",
    position: "fixed",
    top: "95%",
    right: 0,
    transform: "translateY(-50%)",
    display: cart?.showMobileIsland ? "none" : "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    background: "#6CDC2D",
    height: "53px",
    borderRadius: "100px",
    width: "50px",
  };

  const combinedStyles =
    router.pathname === "/services/[...slug]" ? defaultStyles : commonStyles;
  return (
    <>
      <header
        className={handleClasses(
          !isCheckout
            ? "header-bar fixed-header"
            : "header-bar fixed-header header-bar-checkout"
        )}
      >
        <nav className={`navbar navbar-expand-lg ${handleClasses("navbar")}`}>
          <SideBar
            ob={ob}
            handleSidebar={handleSidebar}
            showSideBar={showSideBar}
          />
          {!isCheckout ? (
            <a
              onClick={() => router.push("/")}
              className={`navbar-brand ${handleClasses("navbar-brand")}`}
              href="javascript:void(0)"
            >
              <img
                className={handleClasses("logo")}
                src="/assets/header/Logo12.webp"
                alt="logo"
              />
            </a>
          ) : null}

          {isCheckout ? (
            <div className="row w-100 d-flex justify-content-between align-items-center">
              <div className={`col-md-4 col-4 ${isMobile ? "pr-0" : ""}`}>
                <div className="d-flex justify-content-start align-items-center">
                  <div
                    style={isMobile ? { marginLeft: "8px" } : {}}
                    className=""
                  >
                    <IoIosArrowBack
                      style={{
                        fontSize: isMobile ? "12px" : "16px",
                        marginTop: isMobile ? "2px" : "-1px",
                        cursor: "pointer",
                      }}
                    />
                    <span
                      className=""
                      style={{
                        fontSize: isMobile ? "11px" : "14px",

                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setLoading(true);
                        setShowMobileIstand(true);
                        localStorage.setItem("isHeader", true);
                        router.push(
                          `/services/fujairah-mina-al-fajer/${productLink}`
                        );
                      }}
                    >
                      {isMobile ? "Back to Cart " : "Go Back to Cart "}
                      {loading ? (
                        <Spinner
                          animation="grow"
                          role="status"
                          className="ml-2"
                          size="sm"
                          // style={{ width: "15px", height: "15px" }}
                        >
                          <span className="visually-hidden"></span>
                        </Spinner>
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-5">
                <div className="logo-checkout-page">
                  <a
                    onClick={() => router.push("/")}
                    className={`navbar-brand text-center ${handleClasses(
                      "navbar-brand"
                    )}`}
                    href="javascript:void(0)"
                  >
                    <img
                      className={`text-center ${handleClasses("logo")}`}
                      src="/assets/header/Logo12.webp"
                      alt="logo"
                    />
                  </a>
                  <p
                    className="font-weight-bold text-center"
                    style={{
                      fontWeight: "600!important",
                      fontSize: isMobile ? "14px" : "18px",
                      marginTop: isMobile ? "-8px" : "",
                    }}
                  >
                    Secure Checkout
                  </p>
                </div>
              </div>

              <div
                className={`col-md-4 col-3 ${
                  isMobile ? "" : handleClasses("nav-bar-custom-4")
                }`}
              >
                <div
                  className={handleClasses("mobmenu mobmenu-checkout")}
                  style={{ display: "none" }}
                >
                  {!login ? (
                    <span
                      onClick={() => handleLogin()}
                      className={handleClasses("credmobile")}
                    >
                      <i className="fa-regular fa-circle-user" />
                    </span>
                  ) : null}
                  {login ? (
                    <span
                      className={`menumobile ${handleClasses(
                        "menumobile"
                      )} chekcout-sidebar-hide`}
                    ></span>
                  ) : null}
                </div>
                <div
                  className={handleClasses(
                    "right-action-nav right-action-nav-checkout"
                  )}
                >
                  <ul className={handleClasses("style-ul")}>
                    {loginData.loadingProfile ? (
                      <li
                        className={`${handleClasses(
                          "logged-in coin-block db-layer menu-bar-right"
                        )} checkout-header-loading`}
                      >
                        <Skeleton width={150} height={30} />
                      </li>
                    ) : !login && !loginData.loadingProfile ? (
                      <>
                        <li
                          onClick={() => handleLogin()}
                          className={handleClasses("non-looged-in cred-action")}
                        >
                          <a href="javascript:void(0)">
                            {" "}
                            <i className="fa-solid fa-user" />{" "}
                            <span>Login </span>
                          </a>
                        </li>
                        <li
                          onClick={() => handleSignUp()}
                          className={handleClasses("non-looged-in cred-action")}
                        >
                          <a href="javascript:void(0)">
                            {" "}
                            <i className="fa-solid fa-user-plus" />
                            <span> Signup </span>
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          className={handleClasses(
                            "logged-in coin-block db-layer menu-bar-right"
                          )}
                        >
                          <a
                            style={{ cursor: "text" }}
                            href="javascript:void(0)"
                          >
                            <img alt="user" src={profileImageUrl} />
                            <span>
                              Welcome
                              <strong className={handleClasses("ellipsis")}>
                                {profile.info
                                  ? profile?.info?.FirstName
                                  : loginData?.userAuth?.FirstName || "fetch.."}
                              </strong>
                            </span>
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}

          {!isCheckout && !hideHeader ? (
            <div
              className={`d-flex justify-content-between w-100 align-items-center ${handleClasses(
                "sraction-block"
              )}`}
            >
              <div className={`d-flex ${handleClasses("cal-action")}`}>
                <div className={handleClasses("callbox")}>
                  <a
                    onClick={() => gtmCall()}
                    href="tel:+9718002274357"
                    target="_blank"
                    className={handleClasses("tooltipp expand")}
                    data-title="8002274357"
                  >
                    <img src="/assets/header/phone.svg" alt="phone" />
                  </a>
                </div>
                <div className={handleClasses("callbox")}>
                  <a
                    href="https://api.whatsapp.com/send?phone=9718002274357"
                    target="_blank"
                    onClick={() => gtmWhatsApp()}
                    className={handleClasses("tooltipp expand")}
                    data-title="+978 00 227 4357"
                  >
                    <img src="/assets/header/whatsapp.svg" alt="whatsapp" />
                  </a>
                </div>
              </div>
              <div className={handleClasses("right-tp-header")}>
                <ul>
                  <Search
                    globalData={globalData}
                    handleSearchFocus={closePopups}
                    handleSearchClick={handleSearchClick}
                    isMobieSearch={isMobieSearch}
                  />

                  <li
                    className={handleClasses(
                      !login &&
                        router.pathname === "/services/[...slug]" &&
                        !isMobile
                        ? "locli right-drop-elm"
                        : "locli"
                    )}
                  >
                    {isMobile || router.pathname != "/workshop" ? (
                      <LocationSection
                        login={login}
                        setLoader={setLoader}
                        showToggleLocation={showLocaiton}
                        handleToggleLocation={handleToggleLocation}
                      />
                    ) : null}
                  </li>
                  {login ? (
                    <li>
                      {profile.cars?.length == 0 &&
                      ((!isCurrentCar && isCurrentCar == null) ||
                        (!isCurrentCar?.brand && !globalData?.carData)) ? (
                        isMobile ? (
                          <>
                            <div
                              onClick={() => setShowCarPopUp(!showCarPopUp)}
                              style={{ cursor: "pointer" }}
                              className={`dropdown ${handleClasses(
                                "ad-more-car "
                              )}`}
                            >
                              <div
                                className={`${handleClasses(
                                  "ad-car-dropdown glare-animation pulse-anm"
                                )} glare-animation pulse-anm`}
                              >
                                <strong>Start Here</strong>
                                <i className="fa-solid fa-caret-down" />
                              </div>
                            </div>
                            <ChooseCarMobile
                              show={showCarPopUp}
                              setShow={setShowCarPopUp}
                            />
                          </>
                        ) : (
                          <ChooseCar showCarPopUp={showCarPopUp} />
                        )
                      ) : (
                        <>
                          <CarSection
                            handleChooseCar={handleChooseCar}
                            showCar={showCar}
                            setShowCar={setShowCar}
                            handleSelectCar={handleSelectCar}
                          />
                          <ChooseCar
                            setIsShowCar={setIsShowCar}
                            isShowCar={isShowCar}
                            isLogin={true}
                          />
                        </>
                      )}
                    </li>
                  ) : (
                    <li>
                      {true ? (
                        <>
                          {!isMobile ? (
                            <ChooseCar
                              buttonText={"Change Car"}
                              showCarPopUp={showCarPopUp}
                            />
                          ) : (
                            <>
                              {(!isCurrentCar && isCurrentCar == null) ||
                              (!isCurrentCar?.brand && !globalData?.carData) ? (
                                <div
                                  onClick={() => setShowCarPopUp(!showCarPopUp)}
                                  style={{ cursor: "pointer" }}
                                  className={`dropdown ${handleClasses(
                                    "ad-more-car "
                                  )}`}
                                >
                                  <div
                                    className={`${handleClasses(
                                      "ad-car-dropdown glare-animation pulse-anm"
                                    )} glare-animation pulse-anm`}
                                  >
                                    <strong>Start Here</strong>
                                    <i className="fa-solid fa-caret-down" />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="demo">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className={`dropdown ${handleClasses(
                                        "ad-more-car active-car-val show"
                                      )}`}
                                    >
                                      <div
                                        onClick={() =>
                                          setShowCarPopUp(!showCarPopUp)
                                        }
                                        className={handleClasses(
                                          "ad-car-dropdown"
                                        )}
                                        id="dropdownCar"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <label>
                                          <img
                                            className={handleClasses("brimage")}
                                            src={
                                              isCurrentCar.brandUrl ||
                                              "/assets/header/demo.webp"
                                            }
                                            alt="brand"
                                          />
                                        </label>
                                        <strong>Change Car</strong>
                                        <span>
                                          {isCurrentCar.brand
                                            ? `${isCurrentCar.brand} - ${isCurrentCar.modal}`
                                            : "fetching..."}
                                        </span>
                                        <i className="fa-solid fa-caret-down" />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <ChooseCarMobile
                                show={showCarPopUp}
                                setShow={setShowCarPopUp}
                              />
                            </>
                          )}
                        </>
                      ) : null}
                    </li>
                  )}

                  {isShowCart && cart.products.length > 0 && cart.workshop ? (
                    <li
                      style={{ cursor: "pointer", position: "relative" }}
                      onClick={() => handleCart()}
                      className={handleClasses("cartitems dsp-view")}
                      ref={cartRef}
                    >
                      <i
                        style={{ color: "rgb(108, 220, 45)" }}
                        className="fa-solid fa-cart-shopping "
                      />
                    </li>
                  ) : null}
                </ul>
              </div>
              <div
                className={handleClasses("mobmenu")}
                style={{ display: "none" }}
              >
                {isShowCart && cart.workshop ? (
                  <span
                    style={combinedStyles}
                    onClick={() => handleCart(true)}
                    className={handleClasses("cartitems-mob mob-view")}
                  >
                    {router.pathname === "/services/[...slug]" ? (
                      <TiShoppingCart
                        size="150px"
                        fontSize="40px"
                        style={{
                          marginLeft: "13px",
                          paddingTop: "0px",
                          color: "white",
                          zIndex: 100,
                        }}
                      />
                    ) : (
                      <i
                        className="fa-solid fa-cart-shopping "
                        style={{
                          marginLeft: "13px",
                          paddingTop: "0px",
                          color: "rgb(108, 220, 45)",
                        }}
                      />
                    )}
                    <div
                      className="relative-btn-view-cart cartdot"
                      style={{
                        left: "-9px",
                        height: "20px",
                        display: "block",
                      }}
                    >
                      <div
                        className="btn-dot-view-web "
                        style={{
                          display:
                            router.pathname === "/services/[...slug]"
                              ? "block"
                              : "none",
                        }}
                      ></div>
                      <div className="span-layer-view-web-blue"></div>
                      <div className="span-layer-view-web-blue"></div>
                    </div>
                  </span>
                ) : null}

                <span className={handleClasses("searchmobile")}>
                  <i
                    onClick={() => handleSearchClick()}
                    className="fa-solid fa-magnifying-glass"
                  />
                </span>
                <span className={handleClasses("searchmobile")}>
                  {/* <i
                   onClick={() => handleworkshop()}
                    className="fa-solid fa-shop"
                  /> */}
                  <img
                    onClick={() => handleworkshop()}
                    width={29}
                    height={28}
                    style={{ marginBottom: "6px" }}
                    src="/assets/header/ob-network-new.svg"
                    alt="Image"
                  />
                </span>
                {loginData.loadingProfile ? (
                  <span className="header-loader">
                    <Skeleton height={40} width={40} />
                  </span>
                ) : !login && !loginData.loadingProfile ? (
                  <span
                    onClick={() => handleLogin()}
                    className={handleClasses("credmobile")}
                  >
                    <i className="fa-regular fa-circle-user" />
                  </span>
                ) : (
                  <span
                    onClick={() => handleSidebar()}
                    className={`menumobile ${handleClasses("menumobile")}`}
                  >
                    <i className="fa-solid fa-bars" />
                  </span>
                )}
              </div>
            </div>
          ) : null}
        </nav>
        {!isCheckout && !hideHeader ? (
          <div
            className={handleClasses(
              !cart.showMobileIsland
                ? "bt-header"
                : "bt-header custom-bt-header"
            )}
          >
            <div className="container-fluid">
              <div className="row">
                <div
                  className={`col-8 ${handleClasses(
                    "nav-col nav-bar-custom-8"
                  )}`}
                >
                  <div className={handleClasses("nvlink-div overflow-x-auto")}>
                    <ul
                      // data-aos="fade-up"
                      className={`nav ${handleClasses("nav")}`}
                    >
                      {!globalData.hideHeader && router.pathname == "/" ? (
                        <div
                          className={
                            login ? "marquee-header" : "marquee-header-nologin"
                          }
                        >
                          <marquee>
                            <li
                              // data-aos="fade-up"
                              className={"nav-item custom-home-header-text"}
                            >
                              <a>
                                Welcome to the Trusted Open Bonnet Experience,
                                your One Stop Destination for Car Repair and
                                Maintenance Services
                              </a>
                            </li>
                          </marquee>
                        </div>
                      ) : (categories &&
                          categories.length &&
                          globalData?.currentCategory.length == 0 &&
                          !workshopCategory.length) ||
                        router.pathname != "/services/[...slug]" ? (
                        categories.map((item) => {
                          if (item.Name !== "New Tyres")
                            return (
                              <>
                                <li
                                  onClick={() => handleCategory(item)}
                                  className={
                                    currentCateogory == item.ID &&
                                    router.pathname == "/services/[...slug]"
                                      ? `nav-item active`
                                      : "nav-item"
                                  }
                                >
                                  <a
                                    style={
                                      cart.workshop &&
                                      router.pathname == "/services/[...slug]"
                                        ? { cursor: "text" }
                                        : {}
                                    }
                                    className={
                                      currentCateogory == item.ID
                                        ? `nav-link ${handleClasses(
                                            "nav-link"
                                          )}`
                                        : `nav-link ${handleClasses(
                                            "nav-link"
                                          )}`
                                    }
                                    href="javascript:void(0)"
                                  >
                                    {item.Name}
                                  </a>
                                </li>
                                <li
                                  onClick={() => handleCategory(item)}
                                  className={
                                    currentCateogory == item.ID &&
                                    router.pathname == "/services/[...slug]"
                                      ? `nav-item active`
                                      : "nav-item"
                                  }
                                >
                                  {/* <a
                                  style={
                                    cart.workshop &&
                                      router.pathname == "/services/[...slug]"
                                      ? { cursor: "text" }
                                      : {}
                                  }
                                  className={
                                    currentCateogory == item.ID
                                      ? `nav-link ${handleClasses("nav-link")}`
                                      : `nav-link ${handleClasses("nav-link")}`
                                  }
                                  href="javascript:void(0)"
                                >
                                  New Tyres
                                </a> */}
                                </li>
                              </>
                            );
                        })
                      ) : null}
                      {workshopCategory &&
                      workshopCategory.length &&
                      globalData.currentCategory &&
                      router.pathname == "/services/[...slug]"
                        ? workshopCategory.map((item) => {
                            if (item.Name !== "New Tyres" || item.Id !== 8)
                              return (
                                <li
                                  onClick={() => handleCategoryProduct(item)}
                                  className={
                                    currentCateogory == item.ID &&
                                    router.pathname == "/services/[...slug]"
                                      ? `nav-item active arrow-active`
                                      : "nav-item"
                                  }
                                >
                                  <a
                                    className={
                                      currentCateogory == item.ID
                                        ? `nav-link active ${handleClasses(
                                            "nav-link"
                                          )}`
                                        : `nav-link ${handleClasses(
                                            "nav-link"
                                          )}`
                                    }
                                    href="javascript:void(0)"
                                  >
                                    {item.Name}
                                  </a>
                                </li>
                              );
                          })
                        : null}
                    </ul>
                    {/* {cart.workshop && cart?.showMobileIsland &&
                      null} */}
                  </div>
                </div>

                <div
                  className={`col-md-4 ${handleClasses("nav-bar-custom-4")}`}
                >
                  <div className={handleClasses("right-action-nav")}>
                    <ul className={handleClasses("style-ul")}>
                      {loginData?.loadingProfile ? (
                        <>
                          <li
                            className={`${handleClasses(
                              "workshop-link"
                            )} d-flex justify-content-center align-items-center px-2 header-loader`}
                          >
                            <Skeleton width={90} height={25} />
                          </li>
                          <li className="d-flex justify-content-center align-items-center px-2 header-loader">
                            <Skeleton width={120} height={25} />
                          </li>
                          <li className="d-flex justify-content-center align-items-center px-2 header-loader">
                            <Skeleton width={135} height={25} />
                          </li>
                        </>
                      ) : !login && !loginData?.loadingProfile ? (
                        <>
                          <li
                            onClick={() => handleworkshop()}
                            className={handleClasses("workshop-link")}
                            style={{
                              background: "#f3f4ff",
                              overflow: "hidden",
                            }}
                          >
                            <a href="javascript:void(0)">
                              {" "}
                              {/* <i className="fa-solid fa-shop" />{" "} */}
                              <span>
                                {" "}
                                <img
                                  width={110}
                                  src="/assets/header/OBNetworkLogo_preview_rev_1.webp"
                                  alt="Image"
                                />{" "}
                              </span>
                            </a>
                          </li>
                          <li
                            onClick={() => handleLogin()}
                            className={handleClasses(
                              "non-looged-in cred-action"
                            )}
                          >
                            <a href="javascript:void(0)">
                              {" "}
                              <i className="fa-solid fa-user" />{" "}
                              <span>Login </span>
                            </a>
                          </li>
                          <li
                            onClick={() => handleSignUp()}
                            className={handleClasses(
                              "non-looged-in cred-action"
                            )}
                          >
                            <a href="javascript:void(0)">
                              {" "}
                              <i className="fa-solid fa-user-plus" />
                              <span> Signup </span>
                            </a>
                          </li>
                        </>
                      ) : (
                        <>
                          <li
                            onClick={() => handleworkshop()}
                            style={{
                              background: "#f3f4ff",
                              overflow: "hidden",
                            }}
                            className={handleClasses("workshop-link")}
                          >
                            <a href="javascript:void(0)">
                              {" "}
                              {/* <i className="fa-solid fa-shop" />{" "} */}
                              <span>
                                {" "}
                                <img
                                  width={100}
                                  src="/assets/header/OBNetworkLogo_preview_rev_1.webp"
                                  alt="Image"
                                />{" "}
                              </span>
                            </a>
                          </li>
                          <li
                            className={handleClasses(
                              "logged-in coin-block db-layer"
                            )}
                          >
                            <a
                              onClick={() => {
                                setLoader(true);
                                router.push("/profile/ob-points");
                              }}
                              href="javascript:void(0)"
                            >
                              <img src="/assets/header/ob-coin.webp" />
                              <span>
                                OB CashPoints
                                <strong>
                                  {parseFloat(ob).toFixed(0) || 0}
                                </strong>
                              </span>
                            </a>
                          </li>
                          <li
                            onClick={() => handleSidebar()}
                            className={handleClasses(
                              "logged-in coin-block db-layer menu-bar-right"
                            )}
                          >
                            <a href="javascript:void(0)">
                              <img src={profileImageUrl} alt="user" />
                              <span>
                                Welcome
                                <strong className={handleClasses("ellipsis")}>
                                  {profile.info
                                    ? profile?.info?.FirstName
                                    : loginData?.userAuth?.FirstName ||
                                      "fetch.."}
                                </strong>
                              </span>
                              <i className="fa-solid fa-bars" />
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </header>
      {globalData.showNotification ? (
        <Notification isMobile={isMobile} show={globalData.showNotification} />
      ) : null}
      <UserAuthentication
        setAuthBoxType={setAuthBoxType}
        authBoxType={authBoxType}
        setLogHide={setLogHide}
        setCarHide={setCarHide}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoginData: () => {
      dispatch(clearLoginData());
    },
    getModel: (id) => {
      dispatch(getModel(id));
    },
    getCarBrandModal: (
      brand,
      modal,
      logoBrand,
      logoModal,
      brandId,
      modelId,
      slug = null
    ) => {
      dispatch(
        getCarBrandModal(
          brand,
          modal,
          logoBrand,
          logoModal,
          brandId,
          modelId,
          slug
        )
      );
    },
    getUserAuth: () => {
      dispatch(getUserAuth());
    },
    setAuth: (data) => {
      dispatch(setAuth(data));
    },
    fetchLocationData: () => {
      dispatch(fetchLocationData());
    },
    getLocationData: (lat, lng, address) => {
      dispatch(getLocationData(lat, lng, address));
    },
    setLoginPopup: (stage) => {
      dispatch(setLoginPopup(stage));
    },
    fetchCars: () => {
      dispatch(fetchCars());
    },
    setCarPopup: (status) => {
      dispatch(setCarPopup(status));
    },
    setShowMobileIstand: (flag) => {
      dispatch(setShowMobileIstand(flag));
    },
    setCartProducts: (data) => {
      dispatch(setCartProducts(data));
    },
    setCartJobItems: (data) => {
      dispatch(setCartJobItems(data));
    },
    setCartAddons: (data) => {
      dispatch(setCartAddons(data));
    },
    setCartWorkshop: (data) => {
      dispatch(setCartWorkshop(data));
    },
    toggleSearch: (stage) => {
      dispatch(toggleSearch(stage));
    },
    toggleSosForm: (stage) => {
      dispatch(toggleSosForm(stage));
    },
    toggleChooseCar: (stage) => {
      dispatch(toggleChooseCar(stage));
    },
    toggleSignupForm: (stage) => {
      dispatch(toggleSignupForm(stage));
    },
    toggleLoginForm: (stage) => {
      dispatch(toggleLoginForm(stage));
    },
    toggleLocation: (stage) => {
      dispatch(toggleLocation(stage));
    },
    toggleManageCar: (stage) => {
      dispatch(toggleManageCar(stage));
    },
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    getVariants: (id, mId, WId) => {
      dispatch(getVariants(id, mId, WId));
    },
    setAccessLocation: (data) => {
      dispatch(setAccessLocation(data));
    },
    fetchCustomerCart: () => {
      dispatch(fetchCustomerCart());
    },
    getProfileData: () => {
      dispatch(getProfileData());
    },
    toggleUpdateProfile: (data) => {
      dispatch(toggleUpdateProfile(data));
    },
    setOtpScreen: (data) => {
      dispatch(setOtpScreen(data));
    },
    setCartStage: (stage) => {
      dispatch(setCartStage(stage));
    },
    setCartJourney: (stage) => {
      dispatch(setCartJourney(stage));
    },
    setSelctedId: (stage) => {
      dispatch(setSelctedId(stage));
    },
    setLayer: (stage) => {
      dispatch(setLayer(stage));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);