import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { setOtpScreen } from "redux/actions/globalAction";

function VerifyOTP({
  onOTPVerify,
  handleSubmit,
  register,
  errors,
  isSubmitting,
  otpValid,
  setOtpValid,
  phoneNumber,
  setVerifyOtpSection,
  resendOtp,
  isCheckout,
  setOtpScreen,
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [timer, setTimer] = useState(45);
  const timeOutCallback = useCallback(() => {
    setTimer((currTimer) => currTimer - 1);
  }, []);

  useEffect(() => {
    if (timer > 0) setTimeout(timeOutCallback, 1000);
    else setIsDisabled(false);
  }, [timer, timeOutCallback]);

  const handleResendOtp = () => {
    setIsDisabled(true);
    setTimer(45);
    resendOtp({ otp: "", phoneNumber });
  };

  return (
    <form
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: !isCheckout ? "20%" : "0px",
      }}
      onSubmit={handleSubmit(onOTPVerify)}
    >
      <div className="container text-center">
        <img src="/assets/user.svg" alt="logo" width={120} className="m-2" />
        <p>
          OTP sent to +971 {phoneNumber},{" "}
          <a
            className="text-primary"
            onClick={() => {
              if(setOtpScreen)
                setOtpScreen(false);
              setVerifyOtpSection(false);
            }}
          >
            Change Number
          </a>
        </p>
        <div
          style={{
            height: "40px",
            padding: "8px",
            margin: "5px 30px",
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #000",
            borderRadius: "20px",
          }}
        >
          <input
            type="password"
            placeholder="OTP"
            style={{ border: "none" }}
            className="w-100"
            onChange={() => setOtpValid(false)}
            {...register("otp", { required: true })}
          />
        </div>
        {errors.otp && <span className="text-danger">OTP is required</span>}
        {otpValid && !errors.otp && (
          <span className="text-danger">Enter Valid OTP</span>
        )}
        <button
          className="button-submit shine-animation-new"
          style={{ border: "none", background: "#000", color: "#fff" }}
          type="submit"
          // onClick={()=>{
          //   setLogin(true)
          //   setHide(false);
          //   setLogHide(false);
          //   setCarHide(false);
          //   setBrandHide(false);
          // }}
          disabled={isSubmitting}
        >
          Verify
          {isSubmitting && (
            <Spinner animation="grow" role="status" className="ml-2" size="sm">
              <span className="visually-hidden"></span>
            </Spinner>
          )}
        </button>
        {/* <button
          className="button-submit"
          style={{ border: "none", opacity: isDisabled ? "0.5" : "1" }}
          type="button"
          onClick={handleResendOtp}
          disabled={isDisabled}
        >
          Resend OTP {isDisabled ? `in ${timer}` : " "}
          {isSubmitting && (
            <Spinner animation="grow" role="status" className="ml-2" size="sm">
              <span className="visually-hidden"></span>
            </Spinner>
          )}
        </button> */}
        <p className="mt-2">
          Didn't receive OTP yet,
          <span
            style={{
              color: "#0056b3",
              cursor: "pointer",
              pointerEvents: isDisabled ? "none" : "",
            }}
            onClick={handleResendOtp}
          >
            {isDisabled ? ` Resend in ${timer}` : " Resend!"}
          </span>
        </p>
        {/* <span style={{ color: "#0056b3", cursor: "pointer" }} onClick={handleResendOtp}>resend!</span></p> */}
      </div>
    </form>
  );
}

export default VerifyOTP;
