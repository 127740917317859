import { handleClassNames } from "lib/cssHelpers";
import React from "react";
import styles from "@assets/css/header.module.css";
import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import Typewriter from "typewriter-effect";
import OutsideClickHandler from "react-outside-click-handler";

import {
  setLoader,
  setSelctedId,
  toggleSearch,
} from "../../redux/actions/globalAction";
import { connect } from "react-redux";
import { fetchSearch } from "../../actions/homePage";
import { isMobile } from "react-device-detect";
import { getVariants } from "redux/actions/apiActions";

const Search = ({
  setLoader,
  isMobieSearch,
  handleSearchFocus,
  toggleSearch,
  globalData,
  setSelctedId,
  cart,
  getVariants
}) => {
  const [focused, setFocused] = useState(false);
  const [data, setData] = useState([]);
  const [getText, setText] = useState("");
  const [dataO, setDataO] = useState("");
  const [searchData, setSearchData] = useState([]);

  const onFocus = () => {
    setFocused(true);
  };

  const router = useRouter();
  const onBlur = () => setFocused(false);
  useEffect(async () => {
    const response = await fetchSearch();
    setSearchData(response?.Data);
  }, []);

  useEffect(() => {
    let data = [];
    if (dataO == "" && searchData?.length != 0) {
      data = searchData?.map((item, index) => {
        let name;
        if (item.JourneyVariantName == null) {
          name = item.JourneyCategoryName + " (Category)";
        } else {
          name = item.JourneyVariantName + " (Job)";
        }
        return { ...item, id: index, name };
      });
      setDataO(data);
    }
  }, [searchData]);

  useEffect(() => {
    if (!globalData.searchForm) {
      setData([]);
    }
  }, [globalData.searchForm]);

  const search = (e) => {
    toggleSearch(true);
    let text = String(e.target.value).toLowerCase();
    setText(text);
    if (text.length == 0) {
      setData([]);
      toggleSearch(false);
    } else {
      const filteredBrands = dataO?.filter((e) => {
        return (
          e.JourneyVariantName?.toLowerCase().includes(text) ||
          e.JourneyCategoryName?.toLowerCase().includes(text)
        );
      });
      setData([]);
      setData(filteredBrands);
    }
  };

  const handleSearchItemClick = (e) => {
    console.log("e", e);
    // return
    setLoader(true);
    if (router.pathname == "/services/[...slug]") {
      if (e.JourneyCategoryId) {
        setSelctedId(e.JourneyCategoryId);
        getVariants(e.JourneyCategoryId, globalData?.carData?.modelId, cart.workshop?.Id);
        setText("")
      }
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
    setData([]);
    setTimeout(() => {
      toggleSearch(false);
    }, 1000);
    if (e?.JourneyVariantName == null) {
      router.push(`/services/dubai-silicon-oasis/${e?.JourneyCategorySlug}`);
    } else {
      router.push(`/services/dubai-silicon-oasis/${e?.JourneyVariantSlug}`);
    }
  };

  const handleClasses = (classValue) => {
    return handleClassNames(classValue, styles);
  };
  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <>
      <li
        style={isMobieSearch ? { display: "list-item" } : {}}
        className={handleClasses("serachli")}
      >
        <div
          onClick={() => handleFocus()}
          className={handleClasses("search-block")}
        >
          <i className="fa-solid fa-magnifying-glass" />
          <i
            onClick={() => {
              handleSearchFocus();
            }}
            className="fa-solid fa-arrow-left close-srch"
            style={
              isMobieSearch && isMobile
                ? { margin: "0px -40px" }
                : { display: "none" }
            }
          />
          {focused ? (
            <input
              type="text"
              className="form-control search-bar-on-focus"
              id="exampleInputEmail1"
              onChange={search}
              onFocus={onFocus}
              onBlur={onBlur}
              autoFocus
            />
          ) : (
            <div
              onClick={() => setFocused(true)}
              className={`form-control ${handleClasses("custom-search-bar")}`}
              style={{ lineHeight: "42px" }}
            >
              {/* <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  skipAddStyles: false,
                  wrapperClassName: "search-text-type",
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("SEARCH ANY CAR SERVICE,REPAIR OR WORKSHOP NAME.")
                    .callFunction(() => { })
                    .pauseFor(500)
                    .deleteAll()
                    .callFunction(() => { })
                    .start();
                }}
              /> */}
              <div className="marquee-container">
                <ul className="marquee">
                  <li className="search-Text">
                    SEARCH ANY CAR SERVICE , REPAIR OR WORKSHOP NAME.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            toggleSearch(false);
            setData([]);
          }}
        >
          {data && data.length && globalData.searchForm ? (
            <div className={handleClasses("search-suggestion")}>
              <ul className={handleClasses("style-ul")}>
                {data.map((item) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSearchItemClick(item)}
                    >
                      {item.JourneyVariantName == null ? (
                        <>{item.JourneyCategoryName}</>
                      ) : (
                        <>{item.JourneyVariantName}</>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : getText && globalData.searchForm ? (
            <div
              className={`text-center ${handleClasses("search-suggestion")}`}
            >
              No Data Found
            </div>
          ) : null}
        </OutsideClickHandler>
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    apiData: state.apiData,
    globalData: state.globalData,
    cart: state.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (flag) => {
      dispatch(setLoader(flag));
    },
    toggleSearch: (stage) => {
      dispatch(toggleSearch(stage));
    },
    setSelctedId: (stage) => {
      dispatch(setSelctedId(stage));
    },
    getVariants: (id, mId, WId) => {
      dispatch(getVariants(id, mId, WId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);