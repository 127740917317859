import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { gtmExitIntentSubmitButtonClick } from "../../lib/google-tag-manager";
import router, { useRouter } from "next/router";
const  ExitIntentPopup = ({ handleClose }) => {
  const [loading, setloading] = useState(false);
  const router = useRouter();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleFormSubmit = async (data) => {
    setloading(true);
    const URL = typeof window !== "undefined" ? window?.location?.href : `https://openbonnet.com/${router?.asPath}`;
    const payload = {
      ...data,
      Type: "Exit-intent-Form",
      RequestPostUrl: URL
    };
    await axios
      .post(`${process.env.NEXT_PUBLIC_API_URL}v2/api/leads`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (res) => {
        const {
          data: { Data: result },
        } = res;
        reset();
        toast.dismiss();
        toast.success("Request successfully submitted!");
        handleClose();
      })
      .catch((err) => {
        console.log("Error ", err);
        setloading(false);
        toast.dismiss();
        toast.error("Something went wrong! Please retry.");
      });
    gtmExitIntentSubmitButtonClick();
  };

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="modal fade custom-fade show"
      id="intentmodal"
      onClick={() => handleClose()}
      style={{ display: "block", zIndex: "100000" }}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-backdrop="static" // Add this attribute to display the backdrop
    >
      <div
        onClick={(e) => handleModalContentClick(e)}
        className="modal-dialog modal-dialog-centered margin-modal"
        role="document"
      >
        <div className="modal-content custom-exit-popup">
          <div className="modalheader">
            <button
              onClick={() => handleClose()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
          <div className="modal-body phbody">
            <div className="row">
              <div className="col-md-6">
                <div className="call-center-img">
                  <img
                    src={
                      !isMobile
                        ? "/assets/exit-mob.svg"
                        : "/assets/exit-web.svg"
                    }
                    alt="EXITPOPUP"
                  />
                </div>
              </div>
              <div className="col-md-6 rightcol-block">
                <div className="outer-form-call">
                  <div className="txt-block-call">
                    <h4>Get a Callback</h4>
                    <h3>From Our Service Advisors!</h3>
                    {/* <p>Fill out the form below for a Callback Request.</p> */}
                  </div>
                  <div className="form-call-block">
                    <div className="formlm ">
                      <i className="fa-solid fa-user" />
                      <input
                        type="text"
                        className={`form-control shadow-sm ${
                          errors.FirstName ? "invalid-input" : ""
                        }`}
                        placeholder="Your Name"
                        {...register("FirstName", {
                          required: true,
                        })}
                      />
                      {errors.FirstName && (
                        <span className="text-danger f-12">
                          Name is required
                        </span>
                      )}
                    </div>
                    <div className="formlm custom-height-formlm ">
                      <i className="fa-solid fa-phone" />
                      <input
                        type="text"
                        className={`form-control shadow-sm ${
                          errors.PhoneNumber ? "invalid-input" : ""
                        }`}
                        placeholder="Phone Number"
                        onPaste={(event) => {
                          event.preventDefault();
                          const text =
                            event.clipboardData.getData("text/plain");
                          const sanitizedText = text.replace(/[^0-9]/g, ""); // remove spaces
                          document.execCommand(
                            "insertText",
                            false,
                            sanitizedText
                          );
                        }}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        maxLength={9}
                        {...register("PhoneNumber", {
                          required: true,
                          validate: (value) => {
                            return value[0] == "5" && value.length === 9;
                          },
                        })}
                      />
                      {errors.PhoneNumber && (
                        <span className="text-danger f-12">
                          Enter valid phone number starting with 5
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="submitblockexp">
                    <button
                      onClick={handleSubmit(handleFormSubmit)}
                      className="btn btnorng"
                      disabled={isSubmitting || loading}
                    >
                      Submit
                      {isSubmitting || loading ? (
                        <Spinner
                          animation="grow"
                          role="status"
                          className="ml-2"
                          size="sm"
                        >
                          <span className="visually-hidden"></span>
                        </Spinner>
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitIntentPopup;