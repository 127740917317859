import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchAvatarData } from "../../../../redux/actions/apiActions";


function AvatarList({ profileUrl, apiData, fetchAvatarData, setProfileUrl, setAvatar }) {

    useEffect(() => {
        fetchAvatarData();
    }, []);

    const updateProfileImage = (item) => {
        setProfileUrl(item.ImageUrl)
        setAvatar(item.Id);
    }

    return <>
        <Row>
            <Col style={{ fontSize: '18px', fontWeight: '700', paddingLeft: '25px' }}>
                Choose Avatar
            </Col>
        </Row>
        <Row style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', padding: '10px' }}>
            {apiData.avatars.map((item, index) => {
                return <Col key={index} xs={4} sm={4} md={3} lg={3} role="button" className="mt-2" onClick={() => updateProfileImage(item)}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '80px', width: '80px', background: '#f3f4ff', borderRadius: '70px', fontSize: '12px', fontWeight: '500', padding: '10px', textAlign: 'center' }}>
                        <img src={item.ImageUrl} alt="profile" height="100%" width="100%" />
                    </div>
                </Col>
            })}


        </Row>
    </>;
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        apiData: state.apiData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAvatarData: () => { dispatch(fetchAvatarData()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarList);