import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { setTourBox } from "../../redux/actions/globalAction";
// import Tour from "reactour";
const Tour = dynamic(() => import("reactour"), { ssr: false });

const TourFlow = ({ steps, storageName, globalData, setTourBox }) => {
  const [isTourOpen, setIsTourOpen] = useState(globalData.isTourOpen);
  const [carTour, setCarTour] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsTourOpen(globalData.isTourOpen)
  }, [globalData.isTourOpen])

  useEffect(() => {
    let carTourData = localStorage.getItem("car-tour");
    // if (carTourData || !(router.pathname != '/services/[...slug]' && isMobile)) {
    if (carTourData || isMobile) {
      return;
    }

    setTimeout(
      () => {
        setCarTour(true);
        localStorage.setItem("car-tour", true);
      },
      router.pathname == "/services/[...slug]" ? 3000 : 10000
    );
  }, []);


  return (
    <>
      {carTour && (
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setTourBox(false)}
          showButtons={false}
          showNavigation={false}
          showNavigationNumber={false}
          showNumber={false}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTourBox: (flag) => {
      dispatch(setTourBox(flag));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourFlow);
