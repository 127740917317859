import API from ".";

export const getDefaultWorkshop = () => API.get("v2/api/workshops/default")
export const getCustomerCart = (Id) => API.get(`v2/api/customer-carts?Id=${Id}`)
export const getSearch = () => API.get("v2/api/search")
export const getEmirates = () => API.get("v2/api/emirates")
export const getBrand = () => API.get("v2/api/car/brand")
export const getJourneyCategories = () => API.get("v2/api/journey-categories")
export const getLowestPackages = (EmirateId, EmirateAreaId, CarCompanyId, CarModelId) => API.get(`v2/api/packages/lowest?EmirateId=${EmirateId}&EmirateAreaId=${EmirateAreaId}&CarCompanyId=${CarCompanyId}&CarModelId=${CarModelId}`);
export const getBrandObServe = () => API.get("v2/api/car/brand/ob-serve")
